import React from 'react'
import image1 from '../../../../assets/pictures/images/person.png'

const PhysicianTable = () => {
  return (
    <div>
        <div className='row physician-box mb-1'>
            <div className='col-2 object-fit p-0 ps-1'>
                <img src={image1} alt=""/>
            </div>
            <div className='col-6 ps-4 d-flex flex-column justify-content-center'>
                <div>ID : sample Id</div>
                <div>Dr.Sreekanth</div>
            </div>
            <div className='col-4 d-flex justify-content-center align-items-center'> 
                <div className={`bg-light border p-1 rounded text-center`}>                    
                    available
                </div>
            </div>
        </div>

        <div className='row physician-box mb-1'>
            <div className='col-2 object-fit p-0 ps-1'>
                <img src={image1} alt=""/>
            </div>
            <div className='col-6 ps-4 d-flex flex-column justify-content-center'>
                <div>ID : sample Id</div>
                <div>Dr.Sreekanth</div>
            </div>
            <div className='col-4 d-flex justify-content-center align-items-center'> 
                <div className={`bg-light border p-1 rounded text-center`}>                    
                    available
                </div>
            </div>
        </div>

        <div className='row physician-box'>
            <div className='col-2 object-fit p-0  ps-1'>
                <img src={image1} alt=""/>
            </div>
            <div className='col-6 ps-4 d-flex flex-column justify-content-center'>
                <div>ID : sample Id</div>
                <div>Dr.Sreekanth</div>
            </div>
            <div className='col-4 d-flex justify-content-center align-items-center'> 
                <div className={`bg-light border p-1 rounded text-center`}>                    
                    available
                </div>
            </div>
        </div>

        <div className='row physician-box'>
            <div className='col-2 object-fit p-0  ps-1'>
                <img src={image1} alt=""/>
            </div>
            <div className='col-6 ps-4 d-flex flex-column justify-content-center'>
                <div>ID : sample Id</div>
                <div>Dr.Sreekanth</div>
            </div>
            <div className='col-4 d-flex justify-content-center align-items-center'> 
                <div className={`bg-light border p-1 rounded text-center`}>                    
                    available
                </div>
            </div>
        </div>
    </div>
  )
}

export default PhysicianTable