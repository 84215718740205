import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "semantic-ui-css/semantic.min.css";
import "cropperjs/dist/cropper.css";
import "./shared/form-components/Form.css";
import "./App.css";

import { Route, Routes } from "react-router-dom";
import Layout from "./core/layout/Layout";
import PatientRegister from "./pages/reception/patient/registration/Registration";
import PhysicianRegister from "./pages/admin/physicians/registration/Registration";
import PatientList from "./pages/reception/patient/patient-list/PatientList";
import Login from "./pages/login/Login";
import RequireAuth from "./core/auth/RequireAuth";
import PatientCard from "./pages/reception/patient/id-card/PatientCard";
import PhysicianList from "./pages/admin/physicians/physician-list/PhysicianList";
import AppointmentList from "./pages/reception/appointment/appointment-list/AppointmentList";
import MakeAppointment from "./pages/reception/appointment/make-appointment/MakeAppointment";
import Settings from "./pages/admin/settings/Settings";
import AppointmentDetails from "./pages/reception/appointment/appointment-details/AppointmentDetails";
import PhysicianAppointmentList from "./pages/physician/appointment/appointment-list/PhysicianAppointmentList";
import PhysicianConsultation from "./pages/physician/consultation/PhysicianConsultation";
import SuperAdmin from "./pages/SuperAdmin/SuperAdmin";
import NewHospital from "./pages/hospital/NewHospital";
import { Navigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/5432/register-admin" element={<SuperAdmin />} />
        <Route
          path="/5432/new-hospital/"
          element={
            localStorage.getItem("accessToken") &&
            localStorage.getItem("role") === "Admin" ? (
              <NewHospital />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Layout />}>
          <Route path="/" element={<Dashboard/>}/>
            <Route path="settings" element={<Settings />} />
            <Route path="physician-register" element={<PhysicianRegister />} />
            <Route
              path="physician-edit/:physician_id"
              element={<PhysicianRegister />}
            />
            <Route path="physician-list" element={<PhysicianList />} />
            <Route path="patient-register" element={<PatientRegister />} />
            <Route path="patient-list" element={<PatientList />} />
            <Route path="appointment-list" element={<AppointmentList />} />
            <Route path="appointment-add" element={<MakeAppointment />} />
            <Route
              path="appointment-details"
              element={<AppointmentDetails />}
            />
            <Route
              path="appointment-consultation/:appointment_id"
              element={<PhysicianConsultation />}
            />
            <Route path="patient-card-generate" element={<PatientCard />} />
            <Route
              path="physician-appointment-list"
              element={<PhysicianAppointmentList />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
