import patient_details from '../../../../../assets/pictures/icons/patient-details-icon.png'
import physician_add from '../../../../../assets/pictures/icons/physician-add-icon.png'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { Form, Nav, Tab, TabPane } from 'react-bootstrap'
import './AppointmentList.css'
import useConsultationServices from '../../../../services/api-services/consultationServices'
import { Modal } from 'antd';
import profilepic from '../../../../../assets/pictures/images/sample_profile.png'
import { MEDIA_URL } from '../../../../axios';
import moment from 'moment';
const AppointmentList = () => {
    const [appointmentList, setAppointmentList] = useState()
    const [filterType, setFilterType] = useState('')
    const [showAppointment,setShowAppointment] = useState(false)
    const [appDetails,setAppDetails] = useState('')
    const navigate = useNavigate()

    const {
            getAppointmentList,
            delPatientAppointment,
            getPatientDetail,
        } = useConsultationServices()
    
    useEffect(() => {
        getAppointments()
    }, [filterType])

    const getAppointments = async () => {
        try {
            var param = {
                status: filterType
            }
            const response = await getAppointmentList(param)
            if (response?.success) {
                setAppointmentList(response?.data)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handleAppointmentEdit = (data)=>{
        if(data?.is_registered_patient){
            navigate('/appointment-add', {
                state:{
                    id:data?.id
                }
            })
        }
        else{
            navigate('/patient-register', {
                state:{
                    appointment_id:data?.id,
                    patient_id:data?.fk_temp_patient
                }
            })
        }
    }

    const handleAppointmentDel = async(id)=>{
        try{
            const response = await delPatientAppointment(id)
            if (response.success){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
            }
        }catch(e){
            console.log(e)
        } 
    }
    const handleDeleteWithConfirmation = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            h5: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonh5: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleAppointmentDel(data.id);
            }
        });
    };

    const handleShowAppointment = async(item) =>{
        try{
            const response = await getPatientDetail(item.id);
            if (response.success){
                setAppDetails(response?.data)
                setShowAppointment(true)
            }
        }catch(e){
            console.log(e)  
        }
    }
    console.log(appDetails)
    return (
        <div className="">
            <Modal
            title={null}
            centered
            open={showAppointment}
            width={1000}
            footer={null} 
            onCancel={()=>setShowAppointment(false)}
            >
            <div className="rounded p-3">
                <div className="row">
                <div className="col-6">
                    <div style={{ padding: '47px 10px' }} className="row">
                    <div className="col-6 d-flex flex-column justify-content-center align-items-start">
                        <h3 style={{ color: '#1585d8' }} className="m-1">#{appDetails.token}</h3>
                        <h2 className="m-1">{appDetails?.patient_details?.name.toUpperCase()}</h2>
                    </div>
                    <div className="col-6 d-flex flex-column justify-content-center align-items-start">
                        <h4 className="m-1">{appDetails?.patient_details?.mobile || "Mobile Number"}</h4>
                        <h4 className="m-1">{appDetails?.patient_details?.email||"E-mail ID"}</h4>
                        <h4 className="m-1">{moment(appDetails?.patient_details?.details?.created_at).format('DD/MM/YYYY')}</h4>
                    </div>
                    </div>
                </div>
                <div className="col-6">
                    <div
                    style={{ backgroundColor: '#e6a14d', borderRadius: 10, padding: '47px 10px', color: 'white' }}
                    className="row"
                    >
                    <div className="col-2 col-3 d-flex justify-content-center align-items-center">
                        <img
                        src={MEDIA_URL + appDetails.physician_details?.image}
                        alt="Profile"
                        style={{ border: '1px solid black', borderRadius: '50%', width: '80px', height: '80px' }}
                        />
                    </div>
                    <div className="col-5 d-flex flex-column justify-content-center align-items-start">
                        <h3 className="m-1">{appDetails?.physician_details?.name}</h3>
                        <h4 className="m-1">{appDetails?.physician_details?.phy_id}</h4>
                    </div>
                    <div className="col-4 col-5 d-flex flex-column justify-content-center align-items-end">
                        <h4 className="m-1">{appDetails?.speciality}</h4>
                        <h4 className="m-1">{appDetails?.physician_details?.qualification.join(',')||"BAMS"}</h4>
                    </div>
                    </div>
                </div>
                </div>
                <div style={{ marginTop: '24px' }} className="row">
                <div className="col-6">
                    <h5 className="m-2">Admission Date: {moment(appDetails?.patient_details?.details?.created_at).format('DD/MM/YYYY')}</h5>
                    <h5 className="m-2">Appointment No: {appDetails?.appointment_id|| "0000"}</h5>
                    <h5 className="m-2">Invoice No: {appDetails?.invoice_number|| "0000"}</h5>
                </div>
                <div className="col-6">
                    <h5 className="m-2">Patient Type: {appDetails?.patient_details?.details?.patient_type.toUpperCase()||"Native"}</h5>
                    <h5 className="m-2">Address: {appDetails?.patient_details?.details?.district} {appDetails?.patient_details?.details?.country}</h5>
                </div>
                </div>
                <div className="col-12 mt-3 px-2 text-center">
                <div className="row align-items-center">
                    <h5 className="col-4">Shift</h5>
                    <h5 className="col-4">Date</h5>
                    <h5 className="col-4">Time</h5>
                </div>
                <div className="row align-items-center rounded" style={{ backgroundColor: '#f5f5f5' }}>
                    <h5 className="col-4 mb-0 py-2">{moment(appDetails?.shift?.from_time, 'HH:mm:ss').format('hh:mm A')} to {moment(appDetails?.shift?.to_time, 'HH:mm:ss').format('hh:mm A')}</h5>
                    <h5 className="col-4 mb-0 py-2">{appDetails?.appointment_date?.split('-').reverse().join('/')}</h5>
                    <h5 className="col-4 mb-0 py-2">{moment(appDetails?.created_at).format('hh:mm A')}</h5>
                </div>
                </div>
            </div>
            </Modal>

            <Tab.Container activeKey={"tab1"}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                            >
                                <img
                                    src={patient_details}
                                    className='details-icon'
                                    alt=''
                                />&nbsp;
                                Appointment List
                            </Nav.Link>
                        </Nav.Item>
                        <div className='d-flex justify-content-end col pe-3'>
                            <div
                                className='tabpane-extra-btns'
                                onClick={() => navigate('/appointment-add')}
                            >
                                <img
                                    src={physician_add}
                                    className='physician-add-icon'
                                    alt=''
                                />&nbsp;&nbsp;
                                Make Appointment
                            </div>
                        </div>
                    </Nav>
                </div>
                <div className="appointmentList-sub-tabpane row">
                    <div className="appointmentList-filter-container col-8 col-9 row mx-0">
                        <div 
                            className={`appointmentList-filter-btn ${filterType==''?'active':''}`}
                            onClick={()=>setFilterType('')}
                        >
                            All
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='draft'?'active':''}`}
                            onClick={()=>setFilterType('draft')}
                        >
                            Draft
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='confirmed'?'active':''}`}
                            onClick={()=>setFilterType('confirmed')}
                        >
                            Confirmed
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='consulting'?'active':''}`}
                            onClick={()=>setFilterType('consulting')}
                        >
                            In Consultaion
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='in_observation'?'active':''}`}
                            onClick={()=>setFilterType('in_observation')}
                        >
                            In Observation
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='completed'?'active':''}`}
                            onClick={()=>setFilterType('completed')}
                        >
                            Completed
                        </div>
                        <div 
                            className={`appointmentList-filter-btn ${filterType=='cancelled'?'active':''}`}
                            onClick={()=>setFilterType('cancelled')}
                        >
                            Cancelled
                        </div>
                    </div>
                    <span className='col-3 col-4'></span>
                </div>
                <div className='patient-list-container'>
                    <Tab.Content className='patient-table-container container-white'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                            <table className='table patient-list-table'>
                                <thead className='patient-list-table-head'>
                                    <tr>
                                        <th>Appointment No</th>
                                        <th>Patient Name</th>
                                        <th>Phone Number</th>
                                        <th>Token</th>
                                        <th>Specification</th>
                                        <th style={{ width: 0 }}>Status</th>
                                        <th style={{ width: 0 }}></th>
                                        <th style={{ width: 0 }}></th>
                                    </tr>
                                </thead>
                                <tbody className='patient-list-table-body'>
                                    {appointmentList && appointmentList.map((item, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td>
                                                    <label className='appointment-id-text'>
                                                        {item?.appointment_id}
                                                    </label>
                                                </td>
                                                <td onClick={()=>handleShowAppointment(item)}>
                                                    <label className='appointment-list-text text-uppercase text-wrap appointmenttextdata' role='button'>
                                                        {item?.patient_details?.name}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-list-text'>
                                                        {item?.patient_details?.mobile}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-token-text'>
                                                        {item?.token}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-list-text'>
                                                        {item?.speciality}
                                                    </label>
                                                </td>
                                                <td className=''>
                                                    <Form.Select
                                                        className='appointment-status-dropdown'
                                                        value={item?.status}
                                                    >
                                                        <option value="draft">Draft</option>
                                                        <option value="confirmed">Confirmed</option>
                                                        <option value="consulting">In Consultation</option>
                                                        <option value="in_observation">In Observation</option>
                                                        <option value="completed">Completed</option>
                                                        <option value="cancelled">Cancelled</option>
                                                    </Form.Select>
                                                </td>
                                                <td className=''>
                                                    <span 
                                                        className="appointment-edit-btn"
                                                        onClick={()=>handleAppointmentEdit(item)}
                                                    >
                                                        <MdOutlineModeEditOutline size={18} />
                                                    </span>
                                                </td>
                                                <td className=''>
                                                    <span 
                                                        className="appointment-del-btn"
                                                        onClick={()=>handleDeleteWithConfirmation(item)}
                                                    >
                                                        <DeleteOutlineIcon size={18} />
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </TabPane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    )
}

export default AppointmentList
