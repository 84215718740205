import React from 'react'

const SubPresentIssue = ({data}) => {
  return (
    <div className='row present1 px-3'>
        <label className='mb-2 fw-bold p-0 h6'>Present Issue</label>
        <div className='col-12 bg-light text-dark p-3 mb-4 rounded-2'>{data?.present_issue}</div>

        <label className='mb-2 fw-bold p-0 h6' >Diagnosis</label>
        <div className='col-12 bg-light text-dark p-3 rounded-2'>{data?.diagnosis}</div>
    </div>
  )
}

export default SubPresentIssue