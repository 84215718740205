import './AppointmentDetails.css'
import patient_details from '../../../../../assets/pictures/icons/patient-details-black-icon.png'
import physician_add from '../../../../../assets/pictures/icons/physician-add-white-icon.png'

import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { toWords } from 'number-to-words'
import { Form, Modal, Nav, Tab, TabPane } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import PrescriptionReciept from '../components/PrescriptionReciept'
import useConsultationServices from '../../../../services/api-services/consultationServices'
import { MEDIA_URL } from '../../../../axios'

const AppointmentDetails = () => {
    const [showReciept, setShowReciept] = useState(false)
    const [appointmentDetails, setAppointmentDetails] = useState('')
    const [appointmentAmount, setAppointmentAmount] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    // const { appointment_id } = uselocation();

    const {
        getPatientDetail
    } = useConsultationServices()

    const getAppointmentDetails = async(id)=>{
        try{
            const response = await getPatientDetail(id)
            if(response?.success){
                setAppointmentDetails(response?.data)
                setAppointmentAmount(response?.data?.invoice_amount)
            }
        }catch{}
    }

    const getAmount = ()=>{
        return toWords(parseInt(appointmentAmount)).toUpperCase() + ' ONLY'
    }

    useEffect(()=>{
        if(location?.state?.appointment_id){
            getAppointmentDetails(location?.state?.appointment_id)
        }
    },[location?.state?.appointment_id])

    return (
        <div className="">
            <Tab.Container activeKey={"tab2"}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                                onClick={() => navigate('/appointment-add',{
                                    state:{
                                        id:location?.state?.appointment_id?location?.state?.appointment_id:null
                                    }
                                })}
                            >
                                <img
                                    src={physician_add}
                                    className='physician-add-icon'
                                    alt=''
                                />&nbsp;
                                Schedule
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab2'
                                onClick={() => navigate('/appointment-details')}
                            >
                                <img
                                    src={physician_add}
                                    className='physician-add-icon'
                                    alt=''
                                />&nbsp;
                                Billing
                            </Nav.Link>
                        </Nav.Item>
                        <div className='d-flex justify-content-end col pe-3'>
                            <div
                                className='tabpane-extra-btns'
                                onClick={() => navigate('/appointment-list')}
                            >
                                <img
                                    src={patient_details}
                                    className='details-icon'
                                    alt=''
                                />&nbsp;&nbsp;
                                Appointment List
                            </div>
                        </div>
                    </Nav>
                </div>
                <div className='patient-list-container'>
                    <div className='row mx-0'>

                        <div className="col-12 page-title-label py-4 ps-4">Appointment Details</div>

                        <div className="col-5 p-4">
                            <div className='white-plain-container d-flex justify-content-center align-items-center'>
                                <div className="apointment-info col-6 px-3">
                                    <div className="apointment-id">{appointmentDetails?.appointment_id}</div>
                                    <div className="apointment-name">{appointmentDetails?.patient_details?.name}</div>
                                </div>
                                <div>
                                    <table>
                                        <tbody className="apointment-data">
                                            <tr>
                                                <td className='pe-3 py-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M5.88081 2.01509L6.85331 1.72259C7.38605 1.56194 7.95904 1.60061 8.46537 1.83139C8.97169 2.06216 9.37676 2.46927 9.60497 2.97675L10.3566 4.64842C10.5529 5.08492 10.6075 5.5719 10.5129 6.04103C10.4183 6.51017 10.1792 6.93791 9.82914 7.26425L8.58331 8.42509C8.54692 8.459 8.52386 8.50482 8.51831 8.55425C8.48164 8.88509 8.70581 9.52925 9.22331 10.4251C9.59831 11.0759 9.93914 11.5334 10.2283 11.7893C10.4308 11.9684 10.5416 12.0068 10.5891 11.9934L12.2641 11.4809C12.7216 11.341 13.2113 11.3477 13.6647 11.5002C14.1181 11.6526 14.5125 11.9431 14.7925 12.3309L15.8591 13.8109C16.1839 14.2609 16.3343 14.8134 16.2825 15.3659C16.2307 15.9184 15.9802 16.4333 15.5775 16.8151L14.8383 17.5151C14.4464 17.8862 13.9652 18.1495 13.4413 18.2793C12.9175 18.4091 12.3689 18.4011 11.8491 18.2559C9.55414 17.6151 7.49664 15.6784 5.65331 12.4859C3.80831 9.28925 3.15998 6.53592 3.75831 4.22509C3.89262 3.70537 4.15855 3.229 4.53045 2.84192C4.90236 2.45483 5.36687 2.17007 5.88081 2.01509ZM6.24164 3.21259C5.93321 3.30534 5.65388 3.47598 5.43056 3.70806C5.20724 3.94013 5.04747 4.22582 4.96664 4.53759C4.46497 6.48092 5.03914 8.92175 6.73581 11.8609C8.43081 14.7959 10.255 16.5126 12.1858 17.0526C12.4977 17.1396 12.8268 17.1443 13.1411 17.0663C13.4554 16.9883 13.7441 16.8303 13.9791 16.6076L14.7175 15.9076C14.9007 15.7341 15.0146 15.5 15.0382 15.2488C15.0618 14.9976 14.9935 14.7463 14.8458 14.5418L13.7791 13.0626C13.6519 12.8861 13.4725 12.7539 13.2662 12.6845C13.06 12.6152 12.8372 12.6122 12.6291 12.6759L10.95 13.1901C9.97498 13.4801 9.09081 12.6959 8.14081 11.0501C7.49997 9.94175 7.20164 9.08342 7.27664 8.41592C7.31498 8.06925 7.47664 7.74925 7.73081 7.51092L8.97664 6.35009C9.13569 6.20167 9.24427 6.00718 9.28717 5.79391C9.33006 5.58063 9.30511 5.35929 9.21581 5.16092L8.46497 3.48925C8.36122 3.25858 8.17709 3.07353 7.94692 2.96865C7.71676 2.86376 7.4563 2.84621 7.21414 2.91925L6.24164 3.21259Z" fill="#747474" />
                                                    </svg>
                                                </td>
                                                <td>:</td>
                                                <td className='p-2  py-1'>{appointmentDetails?.patient_details?.mobile}</td>
                                            </tr>
                                            <tr>
                                                <td className='pe-3 py-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M15.8334 3.33325H4.16675C3.50371 3.33325 2.86782 3.59664 2.39898 4.06549C1.93014 4.53433 1.66675 5.17021 1.66675 5.83325V14.1666C1.66675 14.8296 1.93014 15.4655 2.39898 15.9344C2.86782 16.4032 3.50371 16.6666 4.16675 16.6666H15.8334C16.4965 16.6666 17.1323 16.4032 17.6012 15.9344C18.07 15.4655 18.3334 14.8296 18.3334 14.1666V5.83325C18.3334 5.17021 18.07 4.53433 17.6012 4.06549C17.1323 3.59664 16.4965 3.33325 15.8334 3.33325ZM15.2751 4.99992L10.0001 8.95825L4.72508 4.99992H15.2751ZM15.8334 14.9999H4.16675C3.94573 14.9999 3.73377 14.9121 3.57749 14.7558C3.42121 14.5996 3.33341 14.3876 3.33341 14.1666V6.04159L9.50008 10.6666C9.64433 10.7748 9.81977 10.8333 10.0001 10.8333C10.1804 10.8333 10.3558 10.7748 10.5001 10.6666L16.6667 6.04159V14.1666C16.6667 14.3876 16.579 14.5996 16.4227 14.7558C16.2664 14.9121 16.0544 14.9999 15.8334 14.9999Z" fill="#747474" />
                                                    </svg>
                                                </td>
                                                <td>:</td>
                                                <td className='p-2  py-1'>{appointmentDetails?.patient_details?.email}</td>
                                            </tr>
                                            <tr>
                                                <td className='pe-3 py-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M6.66667 11.6667C6.43056 11.6667 6.2325 11.5867 6.0725 11.4267C5.9125 11.2667 5.83278 11.069 5.83334 10.8334C5.83334 10.5973 5.91334 10.3992 6.07334 10.2392C6.23334 10.0792 6.43111 9.99952 6.66667 10.0001C6.90278 10.0001 7.10084 10.0801 7.26084 10.2401C7.42084 10.4001 7.50056 10.5979 7.5 10.8334C7.5 11.0695 7.42 11.2676 7.26 11.4276C7.1 11.5876 6.90222 11.6673 6.66667 11.6667ZM10 11.6667C9.76389 11.6667 9.56583 11.5867 9.40584 11.4267C9.24583 11.2667 9.16611 11.069 9.16667 10.8334C9.16667 10.5973 9.24667 10.3992 9.40667 10.2392C9.56667 10.0792 9.76445 9.99952 10 10.0001C10.2361 10.0001 10.4342 10.0801 10.5942 10.2401C10.7542 10.4001 10.8339 10.5979 10.8333 10.8334C10.8333 11.0695 10.7533 11.2676 10.5933 11.4276C10.4333 11.5876 10.2356 11.6673 10 11.6667ZM13.3333 11.6667C13.0972 11.6667 12.8992 11.5867 12.7392 11.4267C12.5792 11.2667 12.4994 11.069 12.5 10.8334C12.5 10.5973 12.58 10.3992 12.74 10.2392C12.9 10.0792 13.0978 9.99952 13.3333 10.0001C13.5694 10.0001 13.7675 10.0801 13.9275 10.2401C14.0875 10.4001 14.1672 10.5979 14.1667 10.8334C14.1667 11.0695 14.0867 11.2676 13.9267 11.4276C13.7667 11.5876 13.5689 11.6673 13.3333 11.6667ZM4.16667 18.3334C3.70833 18.3334 3.31583 18.1701 2.98917 17.8434C2.6625 17.5167 2.49945 17.1245 2.5 16.6667V5.00008C2.5 4.54175 2.66333 4.14925 2.99 3.82258C3.31667 3.49591 3.70889 3.33286 4.16667 3.33341H5V1.66675H6.66667V3.33341H13.3333V1.66675H15V3.33341H15.8333C16.2917 3.33341 16.6842 3.49675 17.0108 3.82341C17.3375 4.15008 17.5006 4.5423 17.5 5.00008V16.6667C17.5 17.1251 17.3367 17.5176 17.01 17.8442C16.6833 18.1709 16.2911 18.334 15.8333 18.3334H4.16667ZM4.16667 16.6667H15.8333V8.33341H4.16667V16.6667ZM4.16667 6.66675H15.8333V5.00008H4.16667V6.66675Z" fill="#747474" />
                                                    </svg>
                                                </td>
                                                <td>:</td>
                                                <td className='p-2  py-1'>{new Date(appointmentDetails?.patient_details?.reg_date).toLocaleDateString()}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className="col-7 py-4 pe-4">
                            <div className='white-plain-container doctor-info-container text-light d-flex justify-content-center align-items-center px-4'>
                                <div className="doctor-image col-2 px-2">
                                    <img className='' src={MEDIA_URL+appointmentDetails?.physician_details?.image||"/assets/pictures/images/person.png"} alt="" />
                                </div>
                                <div className="doctor-info w-100 d-flex justify-content-between px-2">
                                    <div className="info-start ">
                                        <div className="info-id">{appointmentDetails?.physician_details?.phy_id}</div>
                                        <div className="info-name">{appointmentDetails?.physician_details?.name}</div>

                                    </div>
                                    <div className="info-end text-end">
                                        <div className="info-department">{appointmentDetails?.speciality}</div>
                                        <div className="info-qualification">{appointmentDetails?.physician_details?.qualification?.join(', ')}</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className=" p-4">
                            <div className='white-plain-container d-flex align-items-center justify-content-center p-5 py-3'>
                                <table className='table patient-list-table'>
                                    <thead className='patient-list-table-head border-light table-head-appointment'>
                                        <tr>
                                            <th>Cunsoltation Mode</th>
                                            <th>Date</th>
                                            <th className='text-center'>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody className='patient-list-table-body table-content-appointment'>
                                        {/* {patientList && patientList.map((item, index) => {
                                                    return ( */}
                                        <tr>
                                            <td>{"OFFLINE"}</td>
                                            <td>{new Date(appointmentDetails?.appointment_date).toLocaleDateString()}</td>
                                            <td className='text-center'>{`${appointmentDetails?.shift?.from_time} to ${appointmentDetails?.shift?.to_time}`}</td>

                                        </tr>
                                        {/* )
                                                })} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className=" p-4">
                            <div className='white-plain-container d-flex flex-column align-items-center justify-content-center p-5 py-3'>
                                <div className="h6">Reciepts</div>
                                <table className='table patient-list-table border-bottom border-info'>
                                    <thead className='patient-list-table-head border-none table-head-appointment'>
                                        <tr>
                                            <th>Sl.No</th>
                                            <th>Particulars</th>
                                            <th>Charges</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-content-appointment'>
                                        {/* {patientList && patientList.map((item, index) => {
                                                    return ( */}
                                        <tr className='border-none'>
                                            <td>{"1"}</td>
                                            <td>{appointmentDetails?.physician_details?.name}</td>
                                            <td>{appointmentDetails?.invoice_amount}</td>
                                            <td>{appointmentDetails?.invoice_amount}</td>
                                        </tr>
                                        {/* )
                                                })} */}
                                    </tbody>
                                </table>
                                <div className="apointment-bill-info w-100 d-flex justify-content-between">
                                    <div className="py-2">{getAmount()}</div>
                                    <div className=" w-25">
                                        <div className="w-100 d-flex justify-content-between py-2 px-4">
                                            <span>
                                                Total Billed
                                            </span>
                                            <span>
                                                {appointmentDetails?.invoice_amount}
                                            </span>
                                        </div>
                                        <div className="w-100 d-flex justify-content-between py-2 px-4">
                                            <span>
                                                Payable
                                            </span>
                                            <span>
                                                {appointmentDetails?.invoice_amount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 row me-0 mt-3 pe-0">
                            <span className='col-6 mt-4' />
                            <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
                                <div
                                    className='form-cancel-btn btn col-3 me-3'
                                    onClick={()=>navigate('/appointment-list')}
                                >
                                    Cancel
                                </div>
                                <div
                                    className='form-next-btn btn col-3 me-0'
                                    onClick={() => setShowReciept(true)}
                                // onClick={functions?.handleFinalSubmit}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Container>
            <Modal
                show={showReciept}
                onHide={() => setShowReciept(false)}
                centered={true}
                size='lg'
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Prescription Reciept
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <div className='custom-modal-content row mx-0'>
                        <PrescriptionReciept
                            setShowReciept={setShowReciept}
                            recieptData={appointmentDetails}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AppointmentDetails