import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import useAccountServices from '../../../../../services/api-services/accountServices'
import useGetListDatas from '../../../../../services/other-services/getListDatas'
import Swal from 'sweetalert2'
import DropDownBox from '../../../../../shared/form-components/dropdown/CustomDropDown'

const IDConfig = () => {
    const [showManageModal, setShowManageModal] = useState(false)
    const [suffixType, setSuffixType] = useState(true)
    const [idConfigList, setIdConfigList] = useState('')
    const [idTypeOptions, setIdTypeOptions] = useState([])
    const [idType, setIdType] = useState('')
    const [nextValue, setNextValue] = useState('')
    const [suffix, setSuffix] = useState('')

    const {
        getIDTypes,
        getNewID
    } = useAccountServices()

    
    const {
        postIdCofig
    } = useAccountServices()

    const {
        getSemanticOptionsList
    } = useGetListDatas()

    const getIDTypeOptions = async() =>{
        try{
            const response = await getIDTypes()
            if(response?.success){
                const data = response?.data
                setIdTypeOptions(getSemanticOptionsList(data, '0', '1'))
            }
            console.log(response?.data)
        }catch{}
    }

    const handleManageBtn = () => {
        getIDTypeOptions()
        setShowManageModal(true)
    }

    const handleCancel = () => {
        setIdType('')
        setNextValue('')
        setSuffix('')
        setSuffixType(true)
        setShowManageModal(false)
    }

    const handleSubmit = async() => {
        if(!idType || !nextValue || !suffix){
            Swal.fire('','Fill out all given fields','error')
        }
        try{
            const data = JSON.stringify(
                {
                    id_type:idType,
                    next_value:nextValue,
                    sub_id:suffix,
                    is_prefix:suffixType
                }
            )
            const response = await postIdCofig(data)
            if(response?.success){
                Swal.fire('', response?.message, 'success')
                getIDConfig()
                handleCancel()
            }
            else{
                Swal.fire('', response?.message, 'error')
            }
        }catch { }
    }

    const editIdLoad = async() =>{
        try{
            const response = await getNewID(idType)
            if(response?.success){
                setNextValue(response?.data?.next_value)
                setSuffix(response?.data?.sub_id)
                setSuffixType(response?.data?.is_prefix)
            }
            else{
                setNextValue('')
                setSuffix('')
                setSuffixType(true)
            }
        }catch{
            setNextValue('')
            setSuffix('')
            setSuffixType(true)
        }
    }

    const getIDConfig = async() => {
        try{
            const response = await getNewID()
            if(response?.success){
                setIdConfigList(response?.data)
            }
            else{
                console.log(response?.errors)
            }
        }catch(err){
            setIdConfigList([])
            console.log(err?.response?.errors)
        }
    }

    useEffect(()=>{
        getIDConfig()
    },[])

    useEffect(()=>{
        if(idType){
            editIdLoad()
        }
    },[idType])

    return (
        <div>
            <Modal
                show={showManageModal}
                onHide={()=>setShowManageModal(false)}
                centered={true}
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Manage ID Configurations
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <div className='custom-modal-content row mx-0'>
                        <div
                            className='col-12 row mx-0'
                        >
                            <DropDownBox
                                label={'ID Type'}
                                containerClass={'col-6 mb-3'}
                                fluid={true}
                                selection={true}
                                options={idTypeOptions}
                                setDataValue={setIdType}
                                selectedValue={idType}
                            />
                            <InputTextBox
                                label={'Next Value'}
                                containerClass={'col-6 mb-3'}
                                onChange={setNextValue}
                                defvalue={nextValue}
                            />
                            <InputTextBox
                                label={'Suffix'}
                                containerClass={'col-6 mb-3'}
                                onChange={setSuffix}
                                defvalue={suffix}
                            />
                            <Form.Group
                                className='col-6 mb-3'
                            >
                                <Form.Label
                                    className='label-text-content'
                                >
                                    Suffix Type
                                </Form.Label>
                                <div className='row mx-0'>
                                    <div className="col-6 ps-0">
                                        <div
                                            className={`input-checkbox-control ${suffixType?'select ':' '}`}
                                            onClick={()=>setSuffixType(true)}
                                        >Prefix</div>
                                    </div>
                                    <div className="col-6 pe-0">
                                        <div
                                            className={`input-checkbox-control ${!suffixType?'select ':' '}`}
                                            onClick={()=>setSuffixType(false)}
                                        >Postfix</div>
                                    </div>
                                    
                                    
                                </div>
                                
                            </Form.Group>
                        </div>
                        <div
                            className='col-12 row my-3'
                        >
                            <span className='col-6' />
                            <div
                                className='col-3'
                            >
                                <div 
                                    className='crop-cancel-btn'
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            </div>
                            <div
                                className='col-3'
                            >
                                <div 
                                    className='crop-submit-btn'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-2 my-2">
                <div 
                    className="tabpane-extra-btns"
                    onClick={handleManageBtn}
                >
                    Manage ID
                </div>
            </div>
            <div className='patient-list-container'>
                <div className="patient-table-container container-white">
                    <table className='table patient-list-table'>
                        <thead className='patient-list-table-head'>
                            <tr>
                                <th>ID Type</th>
                                <th>Next Value</th>
                                <th>Suffix</th>
                                <th>Suffix Type</th>
                                <th>Preview</th>
                            </tr>
                        </thead>
                        <tbody className='patient-list-table-body'>
                        {idConfigList && idConfigList.map((item, index) => {
                            return (
                            <tr key={index}>
                                <td>{item?.id_type_value}</td>
                                <td>{item?.next_value}</td>
                                <td>{item?.sub_id}</td>
                                <td>{item?.is_prefix?'Prefix':'Postfix'}</td>
                                <td>
                                    {item?.complete_id}
                                </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    )
}

export default IDConfig
