import React, { useEffect, useState } from 'react'
import img from "../../../../../../assets/pictures/Ellipse 6.png"
import SubPresentIssue from '../subcontents/SubPresentIssue';
import SubPrescription from '../subcontents/SubPrescription';
import SubSymptoms from '../subcontents/SubSymptoms';
import SubHealthAssessment from '../subcontents/SubHealthAssessment';
import SubInvestigationReport from '../subcontents/SubInvestigationReport';
import SubAshtavidha from '../subcontents/SubAshtavidha';
import useConsultationServices from '../../../../../services/api-services/consultationServices';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const VisitingHistory = () => {

  const [isActive, setIsActive] = useState('');
  const [isSubActive, setIsSubActive] = useState('');
  const [visitingHistory, setVisitingHistory]=useState([]);
  const [selectedHistory, setSelectedHistory]=useState([]);
  const {
      getVisitingHistory,
      getPatientDetail,
  } = useConsultationServices()
  
  const { appointment_id } = useParams();
  
  const getVisitingDetails =async()=>{
      try{
        const resp = await getPatientDetail(appointment_id);
        if (resp.success) {
          const response = await getVisitingHistory(resp?.data?.patient_details?.details?.id)
          if(response?.success){
            setVisitingHistory(response?.data?.appointments)
          }
        }
        
      }catch(err){
          console.log(err?.response)
      }
  }

  const handleClick = (data) => {
    if (selectedHistory?.id == data.id) {
      setSelectedHistory('')
    }
    else {
      setSelectedHistory(data)
    }
  }

  const handleSubClick = (sub) => {
    if (isSubActive == sub) {
      setIsSubActive('')
    }
    else {
      setIsSubActive(sub)
    }    
  }

  const switchContent = () => {
    switch (isSubActive) {

      case 'sub1':
        return <SubPresentIssue 
            data={selectedHistory?.assessment_details}
          />

      case 'sub2':
        return <SubPrescription
          data={selectedHistory?.medical_prescription}
        />
      
      case 'sub3':
        return <SubSymptoms
          data={selectedHistory?.symptoms}
        />

      case 'sub4':
        return <SubAshtavidha
          data={selectedHistory?.ashtavidha_pariraksha}
        />

      case 'sub5':
        return <SubHealthAssessment
          data={selectedHistory?.health_assessment}
        />

      case 'sub6':
        return <SubInvestigationReport
          data={selectedHistory?.medical_test_report}
        />

      default:
        return (
          <div>
          </div>
        )
    }}

  useEffect(()=>{
      getVisitingDetails()
    },[])

  return (
    <div className="card card-container1">
      <div className="row mx-0 pb-2 pt-5 ps-5 ">
        <div className='col-5 p-5'>
          <div className='mb-5 h5'>Visit Date</div>
          <div className='accordion1 ps-3'>
            <span className='accordion-item1'></span>
            {visitingHistory&&visitingHistory?.map((data, index)=>{
              return(
                <>
                  <div className="accordion-item1 mb-4" onClick={() => handleClick(data)} >
                    <img src={img} alt="" className='accordion-img1'/>
                    {data?.appointment_date&&moment(new Date(data?.appointment_date)).format('DD / MMM / YYYY')}
                  </div>
                  <div className={`accordion-content1 ms-3 mb-4 p-0 rounded-0 bg-white ${selectedHistory?.id==data.id? 'd-block' : 'd-none'}`}>
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub1'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub1')} >Present Issue</span> <br className='mb-2' />
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub2'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub2')} >Prescription</span> <br className='mb-2' />
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub3'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub3')}>Symptoms</span><br className='mb-2' />
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub4'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub4')}>Ashtavidha pariraksha</span><br className='mb-2' />
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub5'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub5')}>Health Assessment</span><br className='mb-2' />
                    <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub6'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub6')}>Investigation Report</span><br className='mb-2' />
                  </div>
                </>
              )
            })}
            
            {/* <div className="accordion-item1 mb-4" onClick={() => handleClick('sec2')} >
            <img src={img} alt="" className='accordion-img1'/>
            08 / July / 2023
            </div>
            <div className={`accordion-content1 ms-3 mb-4 p-0 rounded-0 bg-white ${isActive != 'sec2' ? 'd-none' : 'd-block'}`}>
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub1'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub1')}>Present Issue</span> <br className='mb-2' />
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub2'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub2')}>Prescription</span> <br className='mb-2' />
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub3'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub3')}>Symptoms</span><br className='mb-2' />
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub4'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub4')}>Ashtavidha pariraksha</span><br className='mb-2' />
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub5'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub5')}>Health Assessment</span><br className='mb-2' />
              <span>-</span> <span className= {`mouse-pointer px-2 ${isSubActive != 'sub6'? 'fw-normal' : 'fw-bold' }`} onClick={() => handleSubClick('sub6')}>Investigation Report</span><br className='mb-2' />
            </div> */}
            
            <span className='accordion-item1'></span>
          </div>

        </div>
        <div className='col-7 p-5'>
          <div className='mb-5 h5'>Consultation Details</div>
          <div className='text-dark w-100 p-5' style={{'background-color':'#EAEAEA','min-height':'25rem'}}>
            {switchContent()}
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default VisitingHistory