import React from 'react'

const AppointmentTable = () => {
    const appointments = [
        {
            id: '12345',
            patientName: 'Test Name',
            phoneNumber: '+91 98745 61000',
            token: 54,
            specification: 'Ortho',
            status: 'Draft',
        },
        {
            id: '12345',
            patientName: 'Test Name',
            phoneNumber: '+91 98745 61000',
            token: 54,
            specification: 'Ortho',
            status: 'Consulting',
        },
        {
            id: '12345',
            patientName: 'Test Name',
            phoneNumber: '+91 98745 61000',
            token: 54,
            specification: 'Ortho',
            status: 'Draft',
        },
        {
            id: '12345',
            patientName: 'Test Name',
            phoneNumber: '+91 98745 61000',
            token: 54,
            specification: 'Ortho',
            status: 'Waiting',
        },
        
       
        ];
  return (
    <div className="container-fluid">
      <table className="table tabg1 text-center">
        <thead>
          <tr>
            <th>Appt no</th>
            <th>Patient Name</th>
            <th>Phone Number</th>
            <th>Token</th>
            <th>Specification</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment, index) => (
            <tr key={index}>
              <td>
                <a href={`/appointment/${appointment.id}`} className="text-primary">
                  {appointment.id}
                </a>
              </td>
              <td>{appointment.patientName}</td>
              <td>{appointment.phoneNumber}</td>
              <td className="text-primary">{appointment.token}</td>
              <td>{appointment.specification}</td>
              <td>
                <div className={`bg-light border p-1 rounded text-center`}>                    
                    {appointment.status}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AppointmentTable