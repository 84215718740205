import React, { useEffect, useState } from 'react'
import { Form, Popover } from 'react-bootstrap'
import { FiSearch } from 'react-icons/fi'
import calender_icon from '../../../../../../../assets/pictures/icons/calender-icon.png'
import InputBtnGroup from '../../../../../../shared/form-components/form-text/InputBtnGroup'
import InputTextBox from '../../../../../../shared/form-components/form-text/InputTextBox'
import CheckBoxBtn from '../../../../../../shared/form-components/checkbox/CheckBoxBtn'
import TextAreaBox from '../../../../../../shared/form-components/form-textarea/TextAreaBox'
import Calendar from 'react-calendar'
import { TimePicker } from 'react-ios-time-picker'
import moment from 'moment'
import PatientSearchBox from '../../../../../../shared/form-components/search-box/PatientSearchBox'
import DropDownBox from '../../../../../../shared/form-components/dropdown/CustomDropDown'
import { useNavigate } from 'react-router-dom'

const BasicDetails = ({ value, setValue, functions }) => {
  // console.log(value, setValue)
  const [showDOBCalendar, setShowDOBCalendar] = useState(false)
  const [showAdmCalendar, setShowAdmCalendar] = useState(false)
  const navigate = useNavigate()

  const handleAdmTime = (e) => {
    var adm;
    if (e.slice(-2) === "PM") {
      var num = Number(e.slice(0, 2)) + 12
      adm = num + e.slice(2, 5)
    } else {
      adm = e.slice(0, 5)
    }
    setValue?.setTempTime(e)
    setValue?.setAdmTime(adm)
  }
  const patient_type = [
    {
      value: 'native',
      text: 'Native'
    },
    {
      value: 'foreigner',
      text: 'Foriegner'
    }
  ]
  const casualty_status = [
    {
      value: true,
      text: 'Yes'
    },
    {
      value: false,
      text: 'No'
    }
  ]

  const handleDoBCalendar = (e) => {
    setValue?.setDOB(e)
    // getAge(e)
    setShowDOBCalendar(false)
  }
  const handleAdmCalendar = (e) => {
    setValue?.setAdmDate(e)
    setShowAdmCalendar(false)
  }

  function getCurrentTime() {
    var now = new Date()
    var time;
    var mns = now.getMinutes()
    var hrs = now.getHours()
    if (mns < 10) {
      mns = '0' + mns
    }
    if (hrs > 12) {
      hrs = hrs - 12
      if (hrs < 10) {
        hrs = '0' + hrs
      }
      time = hrs + ':' + mns + ' PM'
    }
    else {
      if (hrs < 10) {
        hrs = '0' + hrs
      }
      time = hrs + ':' + mns + ' AM'
    }
    // handleAdmTime(time)
    return time
  }

  const DOBCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar onChange={handleDoBCalendar} value={value?.dOB} />
    </Popover>
  )

  const admCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar onChange={handleAdmCalendar} />
    </Popover>
  )

  function getAge(dob) {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var yearAge = yearNow - yearDob;
    var monthAge;
    if (monthNow >= monthDob)
      monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }
    var dateAge;
    if (dateNow >= dateDob)
      dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;
      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    setValue?.setAgeDate(dateAge)
    setValue?.setAgeMonth(monthAge)
    setValue?.setAgeYear(yearAge)
    // console.log(age)
  }

  const getDOB = ()=>{
    var tempYear = new Date().getFullYear()-parseInt(value?.ageYear)
    setValue?.setDOB(new Date(`${tempYear}-1-1`))
  }

  useEffect(()=>{
    if(value?.dOB){
      getAge(value?.dOB)
    }
  },[value?.dOB])

  // useEffect(()=>{
  //   if(value?.ageYear){
  //     getDOB()
  //   }    
  // },[value?.ageYear])

  return (
    <div className='row'>
      <div className="col-12 page-title-label">Basic Information</div>
      <div className='left-split-container col-6 row'>
        <Form.Group
          className={'col-6 mb-3'}
        >
          <Form.Label
            className='label-text-content'
          >
            Mobile Number *
          </Form.Label>
          <div className="px-0">
            <PatientSearchBox
              handleData={functions?.handleMobileSearch}
              placeholder={'Enter Mobile Number'}
              onChange={setValue?.setMobileNumber}
              defValue={value?.mobileNumber}
              mobileOnly={true}
              icon={
                <div className="search-icon-btn mx-0" >
                  <span><FiSearch size='19px' /></span>
                </div>
              }
            />
          </div>                                   
          
        </Form.Group>
        <InputTextBox
          label={'First Name'}
          required={true}
          containerClass={'col-6 mb-3'}
          defvalue={value?.firstName}
          onChange={setValue?.setFirstName}
        />
        <InputTextBox
          label={'Last Name'}
          containerClass={'col-6 mb-3'}
          defvalue={value?.lastName}
          onChange={setValue?.setLastName}
        />
        <InputBtnGroup
          label={'Date Of Birth'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.dOB && moment(new Date(value?.dOB)).format("DD MMM YYYY")}
          setTogglePopup={setShowDOBCalendar}
          togglePopup={showDOBCalendar}
          popupContent={DOBCalendar}
          popupPlacement={"bottom"}
        />
        <Form.Group
            className={'col-2 mb-3 age-form-container1'}
        >
            <Form.Label
                className='label-text-content'
            >
                Age *
            </Form.Label>
            <Form.Control
                type={'text'}
                value={value?.dOB? value?.ageYear + " years":value?.ageYear}
                className='input-text-control'
                required={true}
                disabled={value?.dOB ? true : false}
                placeholder={'YY'}
                onChange={(e)=>setValue?.setAgeYear(e.target.value)}
                // onBlur={getDOB}
            />
        </Form.Group>
        {/* <InputTextBox
          label={'Age'}
          required={true}
          placeholder={'YY'}
          containerClass={'col-2 mb-3 age-form-container1'}
          // disabled={true}
          onChange={setValue?.ageYear}
          defvalue={value?.ageYear && value?.ageYear + " years"}
        /> */}
        <InputTextBox
          placeholder={'MM'}
          containerClass={'col-2 mb-3 age-form-container2 mt-1'}
          disabled={true}
          defvalue={value?.ageMonth && value?.ageMonth + " months"}
        />
        <InputTextBox
          placeholder={'DD'}
          containerClass={'col-2 mb-3 age-form-container3 mt-1'}
          disabled={true}
          defvalue={value?.ageDate && value?.ageDate + " days"}
        />
        <CheckBoxBtn
          label={'Gender'}
          required={true}
          content={'Male'}
          value={'male'}
          setSelected={setValue?.setGender}
          selected={value?.gender}
          containerClass={'col-2 mb-3 age-form-container1'}
        />
        <CheckBoxBtn
          content={'Female'}
          value={'female'}
          setSelected={setValue?.setGender}
          selected={value?.gender}
          containerClass={'col-2 mb-3 mt-1 age-form-container2'}
        />
        <CheckBoxBtn
          content={'Other'}
          value={'other'}
          setSelected={setValue?.setGender}
          selected={value?.gender}
          containerClass={'col-2 mb-3 mt-1 age-form-container3'}
        />
        <DropDownBox
          label={'Blood Group'}
          containerClass={'col-6 mb-3'}
          fluid={true}
          options={value?.bloodGroupList}
          setDataValue={setValue?.setBloodGroup}
          selectedValue={value?.bloodGroup}
        />
        <DropDownBox
          label={'Marital Status'}
          containerClass={'col-6 mb-3'}
          fluid={true}
          options={value?.maritalStatusList}
          setDataValue={setValue?.setMaritalStatus}
          selectedValue={value?.maritalStatus}
        />
        <InputTextBox
          label={'Email ID'}
          type={'email'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setEmail}
          defvalue={value?.email}
        />
        <Form.Group
          className={'col-6 mb-3 ps-0 pe-4'}
        >
          <div className='row ms-0 d-flex align-items-center'>
            <Form.Label
              className='col-2 label-text-content mb-0'
            >
              UIN
            </Form.Label>
            <div className='col-10 justify-content-end pe-0 d-flex align-items-center'>
              <Form.Check
                inline
                type='checkbox'
                className='input-check-control'
                label='Aadhaar'
                onChange={() => setValue?.setAadhar(value?.aadhar === 'aadhaar' ? '' : 'aadhaar')}
                checked={value?.aadhar === 'aadhaar' ? true : false}
              />
              <Form.Check
                inline
                type='checkbox'
                className='input-check-control me-0 pe-0'
                label='Voter ID'
                onChange={() => setValue?.setAadhar(value?.aadhar === 'voter_id' ? '' : 'voter_id')}
                checked={value?.aadhar === 'voter_id' ? true : false}
              />
            </div>
          </div>
          <Form.Control
            type={'text'}
            className='row ms-0 my-1 input-text-control uin'
            placeholder={''}
            onChange={(e) => setValue?.setUIN(e.target.value)}
            value={value?.uIN}
          />
        </Form.Group>
        <TextAreaBox
          label={'Address Line 1'}
          rows={'2'}
          required={true}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setAddress1}
          defvalue={value?.address1}
        />
        <TextAreaBox
          label={'Address Line 2'}
          rows={'2'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setAddress2}
          defvalue={value?.address2}
        />
        <InputTextBox
          label={'City/Location'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setCity}
          defvalue={value?.city}
        />
        <InputTextBox
          label={'Pincode'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setPincode}
          defvalue={value?.pincode}
        />
        <InputTextBox
          label={'District'}
          containerClass={'col-4 mb-3'}
          onChange={setValue?.setDistrict}
          defvalue={value?.district}
        />
        <InputTextBox
          label={'State'}
          containerClass={'col-4 mb-3'}
          onChange={setValue?.setState}
          defvalue={value?.state}
        />
        <InputTextBox
          label={'Country'}
          required={true}
          containerClass={'col-4 mb-3'}
          onChange={setValue?.setCountry}
          defvalue={value?.country}
        />
      </div>
      <div className='col-6 row ms-2 ps-3 pe-0 me-0'>
        <InputTextBox
          label={'Patient ID'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setPatientId}
          defvalue={value?.patientId}
          disabled={true}
        />
        <InputBtnGroup
          label={'Admission Date'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.admDate && moment(new Date(value?.admDate)).format("DD - MM - YYYY")}
          setTogglePopup={setShowAdmCalendar}
          togglePopup={showAdmCalendar}
          disabled={true}
          popupContent={admCalendar}
          popupPlacement={"bottom"}
        />
        <Form.Group
          className={'col-3 mb-3'}
        >
          <Form.Label
            className='label-text-content'
          >
            Admission Time
          </Form.Label>
          <TimePicker
            inputClassName='adm-input-timepicker'
            use12Hours
            onSave={handleAdmTime}
            // pickerDefaultValue={value?.tempTime}
            value={value?.tempTime}
          />
        </Form.Group>
        <InputTextBox
          label={'File No'}
          containerClass={'col-4 mb-3'}
          onChange={setValue?.setFileNum}
          defvalue={value?.fileNum}
        />
        <DropDownBox
          label={'Casualty'}
          containerClass={'col-5 mb-3'}
          fluid={true}
          options={casualty_status}
          setDataValue={setValue?.setCasualty}
          selectedValue={value?.casualty}
        />
        <Form.Group
          className={'col-6 mb-3'}
        >
          <Form.Label
            className='label-text-content pe-5 mb-3'
          >
            Reffered Type :
          </Form.Label>
          <div className='d-flex align-items-center'>
            <Form.Check
              inline
              type='checkbox'
              className='input-check-control'
              label='None'
              onChange={() => setValue?.setRefferedType(value?.refferedType === 'NONE' ? '' : 'NONE')}
              checked={value?.refferedType === 'NONE' ? true : false}
            />
            <Form.Check
              inline
              type='checkbox'
              className='input-check-control'
              label='Internal'
              onChange={() => setValue?.setRefferedType(value?.refferedType === 'internal' ? '' : 'internal')}
              checked={value?.refferedType === 'internal' ? true : false}
            />
            <Form.Check
              inline
              type='checkbox'
              className='input-check-control'
              label='External'
              onChange={() => setValue?.setRefferedType(value?.refferedType === 'external' ? '' : 'external')}
              checked={value?.refferedType === 'external' ? true : false}
            />
          </div>
        </Form.Group>
        <DropDownBox
          label={'Patient Type'}
          containerClass={'col-6 mb-3'}
          fluid={true}
          options={patient_type}
          setDataValue={setValue?.setPatientType}
          selectedValue={value?.patientType}
        />
        <InputTextBox
          label={'Emergancy Contact'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setEmgContName}
          defvalue={value?.emgContName}
        />
        <InputTextBox
          label={'Mobile Number'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setEmgContNumber}
          defvalue={value?.emgContNumber}
        />
        <span className='col-3' />
        <InputTextBox
          label={'Relation With Patient'}
          containerClass={'col-6 mb-3'}
          onChange={setValue?.setEmgContRelation}
          defvalue={value?.emgContRelation}
        />
        <span className='col-3' />
        <TextAreaBox
          label={'Notes'}
          rows={'3'}
          containerClass={'col-12 mb-3'}
          onChange={setValue?.setNotes}
          defvalue={value?.notes}
        />
        <span className='col-6 mt-4' />
        <div className="col-6 mt-4 row d-flex justify-content-end me-0 pe-0">
          <div
            className='form-cancel-btn btn col-6 me-3'
            onClick={()=>navigate('/patient-list')}
          >
            Cancel
          </div>
          <div
            className='form-next-btn btn col-6 me-0'
            onClick={functions?.handleBasicDetailsSubmit}
          >
            Save & Next
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicDetails
