import React, { useState } from 'react'
import './Login.css'
import InputTextBox from '../../shared/form-components/form-text/InputTextBox'
import useAuth from '../../hooks/useAuth'
import useAccountServices from '../../services/api-services/accountServices'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Login = () => {
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate()
    
    const { setAuth } = useAuth()
    const { login } = useAccountServices()

    const handleSubmit = async (e) => {

        e.preventDefault()

        try {
            const data = JSON.stringify({
                username: username,
                password: password,
            });
            const response = await login(data)
            if (response?.success) {
                const accessToken = response?.data?.token;
                const role = response?.data?.fk_role;
                const image = response?.data?.image;
                const firstName = response?.data?.first_name;
                const lastName = response?.data?.last_name;
                var name;
                if (lastName){
                    name = firstName +" "+ lastName
                }
                else{
                    name = firstName
                }
                localStorage.setItem("username", username)
                localStorage.setItem("password", password)
                localStorage.setItem("accessToken", accessToken)
                localStorage.setItem("role", role)
                localStorage.setItem("profileImage", image)
                localStorage.setItem("name", name)
                setAuth({ username, password, accessToken, role, image, name })
                switch(role){
                    case 'Hospital':
                        navigate("/")
                        break
                    case 'Physician':
                        navigate("/")
                        break
                    case 'Admin':
                        navigate("/")
                        break
                }
                
            }
            else {
                Swal.fire("", "login failed", "error")
            }
        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <div className=''>
            <div className='login-page-container'>
                <div className='login-form-container row'>
                    <div 
                        className='login-title-label col-12'
                    >
                        Login
                    </div>
                    <InputTextBox
                        label={'Username'}
                        containerClass={'col-12 mb-3'}
                        defvalue={username}
                        onChange={setUsername}
                    />
                    <InputTextBox
                        label={'Password'}
                        containerClass={'col-12 mb-3'}
                        type={'password'}
                        defvalue={password}
                        visible={true}
                        onChange={setPassword}
                    />
                    <div 
                        className='col-12 text-center'
                    >
                        <div 
                            className='btn btn-primary' 
                            onClick={handleSubmit}
                        >
                            Login
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
