import { Link } from "react-router-dom"

const SliderLink = ({ title, icon, classList,path }) => {
    return (
        <Link className="text-decoration-none " to={path}>
            <div className={`sidebar-link d-flex ps-2 my-2 ${classList} `}>
                <div className="sidebar-link-icon col-3"><img src={icon} alt="" /></div>
                <div className="sidebar-link-title">{title}</div>
            </div>
        </Link>
    )
}

export default SliderLink