import React from 'react'
import logo_img from '../../../../../../assets/pictures/logos/img-logo.png'
import logo_txt from '../../../../../../assets/pictures/logos/txt-logo-high.v3.png'
import moment from 'moment/moment'

const IdFrontPreview = ({patient, useref}) => {

  return (
    <div className='patient-card-container' ref={useref}>
      <div className='row mx-0 ps-3 py-2 patient-card-header'>
        <img
          src={logo_img}
          className='hospital-img-logo px-0 col-2'
          alt=""
        />
        <div className="col-9 px-0 text-center">
          <img
            src={logo_txt}
            className='hospital-text-logo'
            alt=""
          />
          <div className='card-contact-info'>
            www.ayurvedam.co.in, Contact: +91 94473 38048
          </div>
        </div>
      </div>
      <div className='row mx-0 py-2 patient-card-id'>
        {patient?.patientId&&patient.patientId}
      </div>
      <div className='row mx-0 py-2 px-3 patient-card-detail1'>
        <div className='col-8'>
          <div className='patient-card-label row mx-0'>Name</div>
          <div className='patient-card-value row mx-0'>
            {patient?.patientName&&patient.patientName.toUpperCase()}
          </div>
        </div>
        <div className='col-4 card-end-data'>
          <div className='patient-card-label row mx-0'>Blood</div>
          <div className='patient-card-value row mx-0'>
            {patient?.patientBlood&&patient.patientBlood}ve
          </div>
        </div>
      </div>
      <div className='row mx-0 py-2 px-3 patient-card-detail2'>
        <div className='col-5'>
          <div className='patient-card-label sub row mx-0'>Contact</div>
          <div className='patient-card-value row mx-0'>
            {patient?.patientMobile&&patient.patientMobile}
          </div>
        </div>
        <div className='col-2 d-flex flex-column align-items-center'>
          <div className='patient-card-label sub row mx-0'>Age</div>
          <div className='patient-card-value row mx-0'>
            {patient?.patientAge&&patient.patientAge}
          </div>
        </div>
        <div className='col-5 card-end-data'>
          <div className='patient-card-label sub row mx-0'>Registered Date</div>
          <div className='patient-card-value row mx-0 ps-2'>
            {patient?.patientRegistered&&moment(patient.patientRegistered).format('DD - MM - YYYY')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdFrontPreview
