import React, { useState } from "react";
import {
  DropdownButton,
  Dropdown,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "antd";
import moment from "moment";

const FilterSortPagination = ({
  filterOptions,
  sortOptions,
  onFilterSelect,
  onSortSelect,
  onSearchChange,
  onPageChange,
  pagination,
  handleDateChange,
  handleRangeDateChange,
}) => {
  const { RangePicker } = DatePicker;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDatePickerChange = (date, dateString) => {
    handleDateChange(dateString);
    setDropdownOpen(false);
  };

  const handleRangePickerChange = (dates) => {
    if (dates && dates.length === 2) {
      const formattedDates = dates.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );
      handleRangeDateChange(formattedDates);
    } else {
      handleRangeDateChange([]);
    }
    setDropdownOpen(false);
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between p-2 px-3 mt-2">
      {/* Filter and Sorting */}
      <div className="d-flex align-items-center">
        {/* Filter Dropdown */}
        <DropdownButton
          id="filter-dropdown"
          title="Filter"
          variant="light"
          className="me-2"
          show={dropdownOpen}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          autoClose="outside"
        >
          {/* Date Picker for Single Date Filter */}
          <Dropdown.Item as="div" className="p-2" onClick={stopPropagation}>
            <div className="d-flex align-items-center">
              <span className="me-2">Filter by Date:</span>
              <DatePicker
                onChange={handleDatePickerChange}
                className="ms-1"
                needConfirm
              />
            </div>
          </Dropdown.Item>

          {/* Range Picker for Date Range Filter */}
          <Dropdown.Item as="div" className="p-2" onClick={stopPropagation}>
            <div className="d-flex align-items-center">
              <span className="me-2">Filter by Date Range:</span>
              <RangePicker
                className="ms-1"
                onChange={handleRangePickerChange}
                needConfirm
              />
            </div>
          </Dropdown.Item>

          {/* Additional Filter Options */}
          {filterOptions.map((option, index) => (
            <Dropdown.Item
              key={index}
              eventKey={option.value}
              onClick={() => {
                onFilterSelect(option.value);
                setDropdownOpen(false);
              }}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        {/* Sorting Dropdown */}
        <DropdownButton
          id="sorting-dropdown"
          title="Sorting"
          variant="light"
          onSelect={onSortSelect}
        >
          {sortOptions.map((option, index) => (
            <Dropdown.Item key={index} eventKey={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      {/* Pagination */}
      <div className="pagination-container d-flex justify-content-center align-items-center pe-5">
        {/* Search */}
        <InputGroup className="w-auto flex-grow-1 px-2">
          <InputGroup.Text id="search-icon">
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Patients Here ..."
            aria-label="Search Patients"
            aria-describedby="search-icon"
            onChange={onSearchChange}
            className="mb-0"
          />
        </InputGroup>

        <Button
          variant="light"
          className="mx-1"
          onClick={() => onPageChange(pagination.page - 1)}
          disabled={pagination.page === 1}
        >
          &laquo; Previous
        </Button>
        <span className="pagination-info mx-2">
          {pagination.page.toString().padStart(2, "0")} of{" "}
          {pagination.totalPages.toString().padStart(2, "0")}
        </span>
        <Button
          variant="warning"
          className="mx-1"
          onClick={() => onPageChange(pagination.page + 1)}
          disabled={pagination.page === pagination.totalPages}
        >
          Next &raquo;
        </Button>
      </div>
    </div>
  );
};

export default FilterSortPagination;
