import React from 'react'
import InputUploadBox from '../../../../../shared/form-components/form-upload/InputUploadBox'

const ChooseItem = ({documents, setDocuments, handleSubmit}) => {
    return (
        <div className={`document-upload-container row`}>
            <div className='col-10 row mx-0'>
                <InputUploadBox
                    containerClass={''}
                    handleFile={setDocuments}
                    fileValue={documents}
                    multiple={true}
                    id={'documents'}
                    height={'20rem'}
                    bgColor={'#fff'}
                />
            </div>
            <div className='col-2 d-flex justify-content-end'>
                <div
                    className='document-add-btn btn'
                    onClick={handleSubmit}
                >
                    Add
                </div>
            </div>
        </div>
    )
}

export default ChooseItem