import React, { useState } from "react";
import InputTextBox from "../../../../../shared/form-components/form-text/InputTextBox";
import TextAreaBox from "../../../../../shared/form-components/form-textarea/TextAreaBox";

import { TbCards } from "react-icons/tb";
import { MdOutlineAddCard } from "react-icons/md";
import DropDownBox from "../../../../../shared/form-components/dropdown/CustomDropDown";

const IdBackContent = ({ value, setValue, functions }) => {
  const [showDropdown, setShowDropdown] = useState();
  const [showNew, setShowNew] = useState(false);

  const handleClick = () => {
    if (showNew) {
      setShowNew(false);
    } else {
      setShowNew(true);
      handleClear();
    }
  };

  const handleClear = () => {
    setValue?.setTemplateName("");
    setValue?.setTitle("");
    setValue?.setOption1("");
    setValue?.setOption2("");
    setValue?.setOption3("");
    setValue?.setOption4("");
  };

  return (
    // <div className="col-12 row mx-5 pe-5">
    //     <div className="col-12 row d-flex align-items-center">
    //         <div className="page-title-label col">
    //             Card Back-Side Details
    //         </div>
    //         <div className="d-flex justify-content-end col pe-3">
    //             <div
    //                 className='tabpane-extra-btns'
    //                 onClick={handleClick}
    //             >
    //                 {showNew?
    //                     <>
    //                         <TbCards size='18px' />
    //                         &nbsp;&nbsp;
    //                         Template List
    //                     </>:
    //                     <>
    //                         <MdOutlineAddCard size='18px' />
    //                         &nbsp;&nbsp;
    //                         New Template
    //                     </>
    //                 }
    //             </div>
    //         </div>
    //     </div>
    //     <div className='left-split-container col-3 row'>
    //         {showNew?
    //             <InputTextBox
    //                 label={'Template Name'}
    //                 containerClass={'col-12 mb-3'}
    //                 defvalue={value?.templateName}
    //                 onChange={setValue?.setTemplateName}
    //             />:
    //             <DropDownBox
    //                 label={'Templates'}
    //                 containerClass={'col-12 mb-3'}
    //                 fluid={true}
    //                 options={value?.templateList}
    //                 setDataValue={functions?.handleTemplate}
    //             />
    //         }
    //         <InputTextBox
    //             label={'Title'}
    //             containerClass={'col-12 mb-3'}
    //             hintId={'card-back-title'}
    //             hint={'** max 25 characters'}
    //             defvalue={value?.title}
    //             onChange={setValue?.setTitle}
    //             maxLength={25}
    //         />
    //     </div>
    //     <div className='col-9 row ms-2 me-0 ps-5 pe-0'>
    //         <TextAreaBox
    //             label={'Point 1'}
    //             containerClass={'col-6 mb-1'}
    //             hintId={'card-back-point1'}
    //             hint={'** max 90 characters'}
    //             defvalue={value?.option1}
    //             onChange={setValue?.setOption1}
    //             maxLength={90}
    //         />
    //         <TextAreaBox
    //             label={'Point 2'}
    //             containerClass={'col-6 mb-1'}
    //             hintId={'card-back-point2'}
    //             hint={'** max 90 characters'}
    //             defvalue={value?.option2}
    //             onChange={setValue?.setOption2}
    //             maxLength={90}
    //         />
    //         <TextAreaBox
    //             label={'Point 3'}
    //             containerClass={'col-6 mb-1'}
    //             hintId={'card-back-point3'}
    //             hint={'** max 90 characters'}
    //             defvalue={value?.option3}
    //             onChange={setValue?.setOption3}
    //             maxLength={90}
    //         />
    //         <TextAreaBox
    //             label={'Point 4'}
    //             containerClass={'col-6 mb-1'}
    //             hintId={'card-back-point4'}
    //             hint={'** max 90 characters'}
    //             defvalue={value?.option4}
    //             onChange={setValue?.setOption4}
    //             maxLength={90}
    //         />
    //     </div>
    //     <div className="col-12 py-4 d-flex justify-content-end">
    //         {showNew&&
    //             <>
    //                 <div
    //                     className='form-previous-btn btn me-4'
    //                     onClick={handleClear}
    //                 >
    //                     Clear
    //                 </div>
    //                 <div
    //                     className='form-next-btn btn me-4'
    //                     onClick={functions?.handleTemplateSubmit}
    //                 >
    //                     Save
    //                 </div>
    //             </>
    //         }
    //     </div>
    // </div>
    <div className="col-12 row mx-0 px-3 px-md-5">
      <div className="col-12 row d-flex align-items-center">
        <div className="page-title-label col">Card Back-Side Details</div>
        <div className="d-flex justify-content-end col">
          <div className="tabpane-extra-btns" onClick={handleClick}>
            {showNew ? (
              <>
                <TbCards size="18px" />
                &nbsp;&nbsp; Template List
              </>
            ) : (
              <>
                <MdOutlineAddCard size="18px" />
                &nbsp;&nbsp; New Template
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 col-lg-3 row">
        {showNew ? (
          <InputTextBox
            label={"Template Name"}
            containerClass={"col-12 mb-3"}
            defvalue={value?.templateName}
            onChange={setValue?.setTemplateName}
          />
        ) : (
          <DropDownBox
            label={"Templates"}
            containerClass={"col-12 mb-3"}
            fluid={true}
            options={value?.templateList}
            setDataValue={functions?.handleTemplate}
          />
        )}
        <InputTextBox
          label={"Title"}
          containerClass={"col-12 mb-3"}
          hintId={"card-back-title"}
          hint={"** max 25 characters"}
          defvalue={value?.title}
          onChange={setValue?.setTitle}
          maxLength={25}
        />
      </div>
      <div className="col-12 col-md-8 col-lg-9 row ps-md-2">
        <TextAreaBox
          label={"Point 1"}
          containerClass={"col-12 col-md-6 mb-3"}
          hintId={"card-back-point1"}
          hint={"** max 90 characters"}
          defvalue={value?.option1}
          onChange={setValue?.setOption1}
          maxLength={90}
        />
        <TextAreaBox
          label={"Point 2"}
          containerClass={"col-12 col-md-6 mb-3"}
          hintId={"card-back-point2"}
          hint={"** max 90 characters"}
          defvalue={value?.option2}
          onChange={setValue?.setOption2}
          maxLength={90}
        />
        <TextAreaBox
          label={"Point 3"}
          containerClass={"col-12 col-md-6 mb-3"}
          hintId={"card-back-point3"}
          hint={"** max 90 characters"}
          defvalue={value?.option3}
          onChange={setValue?.setOption3}
          maxLength={90}
        />
        <TextAreaBox
          label={"Point 4"}
          containerClass={"col-12 col-md-6 mb-3"}
          hintId={"card-back-point4"}
          hint={"** max 90 characters"}
          defvalue={value?.option4}
          onChange={setValue?.setOption4}
          maxLength={90}
        />
      </div>
      <div className="col-12 py-4 d-flex justify-content-end">
        {showNew && (
          <>
            <div className="form-previous-btn btn me-4" onClick={handleClear}>
              Clear
            </div>
            <div
              className="form-next-btn btn me-4"
              onClick={functions?.handleTemplateSubmit}
            >
              Save
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IdBackContent;
