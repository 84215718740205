import React, { useEffect, useState } from 'react'
import MultiInputBox from '../../../../../shared/form-components/multi-input/MultiInputBox'
import PatientSymptoms from '../PatientSymptoms'
import Accordion from '../../../../../shared/form-components/accordian/Accordion'
import TextAreaBox from '../../../../../shared/form-components/form-textarea/TextAreaBox'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import { Button, Form } from 'react-bootstrap'
import viewimg from '../../../../../../assets/pictures/icons/Vector(1).png'
import uploadimg from '../../../../../../assets/pictures/icons/Vector.png'
import useConsultationServices from '../../../../../services/api-services/consultationServices'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import useGetListDatas from '../../../../../services/other-services/getListDatas'
import { FaEdit } from 'react-icons/fa'
import { RiDeleteBin2Fill } from 'react-icons/ri'
import ChooseItem from '../subcontents/ChooseItem'
import { Modal } from 'react-bootstrap'

const AssessmentInvestigation = ({value, setValue}) => {

    const [symIssue, setSymIssue] = useState([])
    const [attribute,setAttribute] = useState('')
    const [content,setContent] = useState('')
    const [ashtaType,setAshtaType] = useState('')
    const [ashtaChoice,setAshtaChoice] = useState([])
    const [ashtaVidha,setAshtaVidha] = useState([])
    const [vitalTypes,setVitalTypes] = useState([])
    const [health,setHealth] = useState('')
    const [healthType,setHealthType] = useState('')
    const [testName,setTestName] = useState('')
    const [remark,setRemark] = useState('')
    const [updatedName,setUpdatedName] = useState('')
    const [testRepo,setTestRepo]=useState([])
    const [listDoc,setListDoc] = useState([])
    const [showListDoc,setShowListDoc] = useState(false)
    const [uploads,setUploads] = useState()
    const [showChoose,setShowChoose] = useState(false)

    const { appointment_id } = useParams();

    const {
        getAppointmentAssessmentdetails,
        getSymptoms,
        getAshtaChoice,
        getAshtaVidha,
        getVitalTypes,
        getHealthAssessment,
        getTestReport,
        getTestDocuments,
    } = useConsultationServices()

    const {
        // postAppointmentAssessmentdetails,
        postSymptoms,
        postAshtaVidha,
        postHealthAssessment,
        postTestReport,
        postMedicalDocumentAdd,
    } = useConsultationServices()

    const {
        delSymptoms,
        delMedicalTestReport,
        delAshtaVidha,
        delHealthAssessment
    }=useConsultationServices()

    const { 
        getMultiInputList
    } = useGetListDatas();

    const {
        putTestReport,
    }=useConsultationServices()
    

    const getAssessmentDetails =async()=>{
        try{
            const response = await getAppointmentAssessmentdetails(appointment_id)
            if(response?.success){
                setValue?.setPrIssue(response?.data?.present_issue)
                setValue?.setDiagnosis(response?.data?.diagnosis)
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    // const postAssessmentdetails = async()=>{
    //     try{
    //         const data={
    //             present_issue:prIssue,
    //             diagnosis:diagnosis
    //         }
    //         const response = await postAppointmentAssessmentdetails(appointment_id ,data)
    //         if(response?.success){
    //             Swal.fire({
    //                 text:response?.message,
    //                 icon:'success',
    //                 timer: 1000,
    //                 showConfirmButton:false
    //             })
    //         }
    //     }catch(err){
    //         console.log(err?.response)
    //     }
    // }

    const getSymptomsDetails = async()=>{
        try{
            const response = await getSymptoms(appointment_id)
            if(response?.success){
                const symptoms=response?.data
                setSymIssue(getMultiInputList(symptoms, "id", "description"));
            }
        }catch(err){
            console.log(err?.response) 
        }
    }

    const handleSymAdd = async (value) => {
        try{
            const data ={
                description:value,
            }
            const response = await postSymptoms(appointment_id,data)
            if(response?.success){
                getSymptomsDetails()
                // Swal.fire({
                //     text:response?.message,
                //     icon:'success',
                //     timer: 1000,
                //     showConfirmButton:false
                // })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const handleSymDel = async (id) => {
        try{
            const response = await delSymptoms(id)
            if(response?.success){
                getSymptomsDetails()
                Swal.fire({
                    text:response?.message,
                    icon:'success',
                    timer: 1000,
                    showConfirmButton:false
                })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const getAshtaChoices = async() => {
        try{
            const response = await getAshtaChoice()
            if (response?.success){
                // console.log(response?.data?.types_choices)
                setAshtaChoice(response?.data?.types_choices)
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const getAshtaVidhaDetails =async() =>{
        try{
            const response =await getAshtaVidha(appointment_id)
            if (response?.success){
                setAshtaVidha(response?.data)
            }
       }catch(e){
            console.log(e.response)
       }
    }
    const handleAttribute = (type, value)=>{
        setAttribute(value)
        if(content){
            if(ashtaType!==type){
                setContent('')
                setAshtaType(type)
            }
        }
        else{
            setAshtaType(type)
        }
    }

    const handleContent = (type, value)=>{
        setContent(value)
        if(attribute){
            if(ashtaType!==type){
                setAttribute('')
                setAshtaType(type)
            }
        }
        else{
            setAshtaType(type)
        }
    }

    const handleAshtaAdd = async(e)=>{
        e.preventDefault()
        if ((!attribute || !content)&&(attribute===''||content==='')){
            Swal.fire({
                text:"Enter both Attribute and Content",
                icon:"info",
                timer:1000,
                showConfirmButton:false,
            })
            return;
        }
        try{
            const data = JSON.stringify({
                type:ashtaType,
                attribute:attribute,
                description:content
            })
            const response = await postAshtaVidha(appointment_id,data)  
            if(response?.success){
                getAshtaVidhaDetails()
                setAshtaType('')
                setAttribute('')
                setContent('')
                Swal.fire(
                    {
                        text:response?.message,
                        icon:'success',
                        timer: 1000,
                        showConfirmButton:false }
                )
            }
        }catch(err){
            console.log(err.response)
        }
        
    }

    const getVitalTypeDetails = async ()=>{
        try{
            const response= await getVitalTypes()
            if(response?.success){
                setVitalTypes(response?.data?.filter((item)=>item.active===true))
            }
        }catch(err){
            console.log(err.response)
        }
    }

    const getHealthAssessmentDetails =async() =>{
        try{
            const response =await getHealthAssessment(appointment_id)
            if (response?.success){
                setHealth(response?.data)
            }
       }catch(e){
            console.log(e.response)
       }
    }

    const handleVitalAttribute = (type, value)=>{
        setAttribute(value)
        if(content){
            if(healthType!==type){
                setContent('')
                setHealthType(type)
            }
        }
        else{
            setHealthType(type)
        }
    }

    const handleVitalContent = (type, value)=>{
        setContent(value)
        if(attribute){
            if(healthType!==type){
                setAttribute('')
                setHealthType(type)
            }
        }
        else{
            setHealthType(type)
        }
    }

    const handleVitalsAdd = async(e)=>{
        e.preventDefault()
        if((!attribute || !content)&&(attribute===''||content==='')){
            Swal.fire(
                {
                    text:"Enter both attributes and content",
                    icon:'info',
                    timer:1000,
                    showConfirmButton:false,
                }
            )
            return;
        }
        try{
            const data = JSON.stringify({
                type:healthType,
                attribute:attribute,
                description:content
            })
            const response = await postHealthAssessment(appointment_id, data)  
            if(response?.success){
                getHealthAssessmentDetails()
                setHealthType('')
                setAttribute('')
                setContent('')
                Swal.fire(
                    {
                        text:response?.message,
                        icon:'success',
                        timer: 1000,
                        showConfirmButton:false }
                )
            }
        }catch(err){
            console.log(err.response)
        }
        
    }

    const getTestReports =async()=>{
        try{
            const response = await getTestReport(appointment_id)
            if(response?.success){
                setTestRepo(response?.data)
                // setTestRemarks(response?.data?.remark)
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const postTestReports = async()=>{
        try{
            const data={
                test_name:testName,
                // remark:diagnosis
            }
            const response = await postTestReport(appointment_id ,data)
            if(response?.success){
                getTestReports()
                setTestName('')
                Swal.fire({
                    text:response?.message,
                    icon:'success',
                    timer: 1000,
                    showConfirmButton:false
                })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const handleUploadDocument =async()=>{
        let item = showChoose
        const data= {
            document:uploads||[]
        }
        const response = await postMedicalDocumentAdd(item,data)
        if(response?.success){
            setUploads([])
            Swal.fire({
                text:response?.message,
                icon:'success',
                timer: 1000,
                showConfirmButton:false
            })
        }
    }

    const getTestDocumentDetails =async() =>{
        try{
            let item = showListDoc
            const response =await getTestDocuments(item)
            if (response?.success){
                setListDoc(response?.data)
            }
       }catch(e){
            console.log(e.response)
       }
    }

    // const handleTestDoc = async(id)=>{
    //         setListDoc(id)

    // }

    const updateTestReport = async(item)=>{
        try{
            const data={
                test_name: item?.test_name,
                remark: item?.remark
            }
            if (remark&&item?.remark!=remark){
                data['remark']=remark
            }
            if(updatedName&&item?.test_name!=updatedName){
                data['test_name']=updatedName
            }
            const response = await putTestReport(item.id ,data)
            if(response?.success){
                getTestReports()
                setTestName('')
                setUpdatedName('')
                setRemark('')
                Swal.fire({
                    text:response?.message,
                    icon:'success',
                    timer: 1000,
                    showConfirmButton:false
                })
            }
        }catch(err){
            console.log(err?.response)
        }
    }
    
    const delMedicalReport =async(id)=>{
        try{
            const response = await delMedicalTestReport(id)
            if(response?.success){
            getTestReports()
            Swal.fire({
                text:response?.message,
                icon:'success',
                timer: 1000,
                showConfirmButton:false
            })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const deleteAshtaVidha =async(id)=>{
        try{
            const response = await delAshtaVidha(id)
            if(response?.success){
                getAshtaVidhaDetails()
                Swal.fire({
                    text:response?.message,
                    icon:'success',
                    timer: 1000,
                    showConfirmButton:false
                })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const deleteHealthAssessment =async(id)=>{
        try{
            const response = await delHealthAssessment(id)
            if(response?.success){
                getHealthAssessmentDetails()
                Swal.fire({
                    text:response?.message,
                    icon:'success',
                    timer: 1000,
                    showConfirmButton:false
                })
            }
        }catch(err){
            console.log(err?.response)
        }
    }

    const deletePress = async (item) => {
        Swal.fire({
          title: 'Delete',
          text: `Are you sure to delete ${item.test_name} ?`,
          icon: "question",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: "Cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Delete',
              text: `Confirm deletion`,
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              denyButtonText: "Cancel",
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                await delMedicalReport(item.id)
              },
              preDeny: () => {
                Swal.fire('Cancelled', '', 'info')
              }
            })
          } 
          })
        }


    useEffect(()=>{
        if (appointment_id){
            getAssessmentDetails()
            getSymptomsDetails()
            getAshtaChoices()
            getAshtaVidhaDetails()
            getVitalTypeDetails()
            getHealthAssessmentDetails()
            getTestReports()
        }
    },[])
    return (
        <div className="card card-container h-100 pb-5">
            <div className="row mx-0 pb-2 pt-5 ps-5 ">
                    <div className="col-6 row me-0" >
                        <div className="col-12 mb-4">
                            <InputTextBox
                                label={'Present Issue'}
                                defvalue={value?.prIssue}
                                onChange={setValue?.setPrIssue}
                                containerclassName={'col-12 mb-4 h-100'}
                            />
                            <Form.Label
                                className='label-text-content mt-3 mb-0'
                            >
                                Symptoms
                            </Form.Label>
                            <PatientSymptoms
                                onAdd={handleSymAdd}
                                data={symIssue}
                                onRemove={handleSymDel}
                            />
                        </div>
                    </div>
                    <div className="col-6 row me-0 ps-0">
                        <div className="col-12 mb-4 pt-3">
                            <Accordion 
                                title="Ashtavidha Pariraksha" 
                                data={ashtaChoice} 
                                data2={ashtaVidha}
                                type={ashtaType}
                                setAttribute={handleAttribute}
                                setContent={handleContent}
                                handleAdd={handleAshtaAdd}
                                handleDelete={deleteAshtaVidha}
                                {...{
                                    content,
                                    attribute
                                }}
                            /> 

                            <Accordion
                                title="Health Assessment"
                                data={vitalTypes}
                                data2={health}
                                type={healthType}
                                setAttribute={handleVitalAttribute}
                                setContent={handleVitalContent}
                                handleVitalAdd={handleVitalsAdd}
                                handleDelete={deleteHealthAssessment}
                                {...{
                                    content,
                                    attribute
                                }}
                                
                            />
                            
                        </div>

                        <div className="col-12 mb-4 ">
                            <TextAreaBox
                                label={'Diagnosis'} 
                                rows={6}
                                defvalue={value?.diagnosis}
                                onChange={setValue?.setDiagnosis}
                                >
                            </TextAreaBox>
                        </div>
                    </div>
                    {/* <div className='row px-3 pe-5 mx-0 justify-content-end'>
                       
                         <button className='col-2  buttonview btn btn-secondary' onClick={postAssessmentdetails}>Save</button>
                         </div>
                    <div className="col-12 px-3 pe-5"><hr /></div> */}
            </div>
    
            <div className="row mx-0 ps-5">
                <div className='col-5 row mx-0' >
                    <InputTextBox 
                        label={
                            'Test Name'
                        }
                        defvalue={testName}
                        onChange={setTestName}
                        placeholder={'Eg : Blood Test'}
                        containerClass={'col-9 col-10 w-100 px-0 pe-1'}
                    />
                    
                    <Button className='col-2 col-3 buttonview mt-4 btn btn-secondary' onClick={postTestReports} >
                        +  ADD
                    </Button>
                      
                </div>
            </div>
            
            <Modal
                show={showChoose}
                onHide={() => setShowChoose(false)}
                centered
                size='lg'
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Test Documents
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <div className='custom-modal-content row mx-0'>
                        <ChooseItem
                            documents={uploads}
                            setDocuments={setUploads}
                            handleSubmit={handleUploadDocument}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showListDoc}
                onHide={() => setShowListDoc(false)}
                centered
                size='lg'
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Test Details
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <div className='custom-modal-content row mx-0'>
                        <div>
                            {listDoc?.map(
                                (data)=>{
                                    <p>{data.document}</p>
                                }
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            
            <div className='testrepo'>
                {testRepo&&
                testRepo.map((data,i)=>{
                    // setUpdatedName(data?.test_name)
                    return(
                        <>
                        <div key={i} className="row mx-0 py-0 ps-5">
                            
                            <div className="col-5 row mx-0">
                                <div className='col-9 col-10 w-100 px-0 pe-1 ' >
                                    <InputTextBox defvalue={data?.test_name} onChange={setUpdatedName} />
                                </div>
                                <Button className='col-2 col-3 buttonview mt-3 btn btn-secondary' >
                                    {/* onClick={handleTestDoc(data?.id)} */}
                                    <img src={viewimg} className='me-1'/>
                                    View
                                </Button>
                            </div>  
                            <div className="col-7 row mx-0 pe-0">
                                <Button className='col-2 col-3 buttonview mt-3 btn btn-secondary' onClick={()=>setShowChoose(data?.id)}>
                                <img src={uploadimg} className='me-1'/>
                                    Upload
                                </Button>
                                <div className='col-9 col-10 w-100 ps-1 pe-5 '>
                                    <div className='row mx-0 my-0 ms-2'>
                                        <div className='col-8 mt-1'>
                                        <InputTextBox
                                        defvalue={data?.remark}
                                        placeholder={'Remarks'}
                                        onChange={setRemark}
                                        /> 
                                        </div>

                                        <div className='col-2 mt-3'>
                                        <Button className='buttonview p-0 btn btn-secondary w-100' onClick={()=>updateTestReport(data)} >
                                            <FaEdit size={'1.5rem'}/>
                                        </Button>
                                        </div>
                                        
                                        
                                        <div className='col-2 mt-3'>
                                        <Button className='buttonview btn btn-secondary w-100' onClick={()=>deletePress(data)} >
                                            <RiDeleteBin2Fill size={'1.5rem'}/>
                                        </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        </>
                    
                        )})
                    }
            </div>
        </div>
        )
}

export default AssessmentInvestigation