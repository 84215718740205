import React, { useEffect, useRef, useState } from "react";
import { MEDIA_URL } from "../../../../../axios";
import moment from "moment";
import { TbPencil } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { Switch } from "antd";

const PhysicianListCard = ({ data, index, functions }) => {
  const [isActive, setIsActive] = useState(true);
  const detailsRef = useRef(null);
  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const [showDetails, setShowDetails] = useState(false);

  const handleShowDetails = () => {
    setShowDetails((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      setShowDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    // <div key={index} className='col-12 col-md-6 pt-1 pb-4 ps-0 pe-4'>
    //     <div className='physician-list-card-container row w-100 mx-0'>
    //         <div className="col-4 col-md-3 col-lg-2 p-0 physician-img-container">
    //             <img
    //                 src={MEDIA_URL+data?.basic_info?.image}
    //                 alt=""
    //                 className="physician-profile-image w-100"
    //             />
    //         </div>
    //         <div className="col-4 col-md-5 col-lg-6 d-flex flex-column justify-content-center">
    //             <div className="physician-list-id">
    //                 <span className='label'>ID : </span>
    //                 <span className='text'>{data?.employee_id}</span>
    //             </div>
    //             <div className="">
    //                 <div className="physician-list-name row mx-0">
    //                     {data?.basic_info?.full_name}
    //                 </div>
    //                 <div className="physician-list-qualification row mx-0">
    //                     {data?.qualifications && data?.qualifications?.map(
    //                         (item, index)=>{
    //                             if(index!==(data.qualifications.length-1)){
    //                                 return item+', '
    //                             }
    //                             else{
    //                                 return item
    //                             }
    //                         }
    //                     )}
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="col-4 col-md-4 physician-list-speciality ps-0">
    //             <div className="row mx-0">
    //                 <div className="col-6 px-0 w-100">
    //                     Speciality:
    //                 </div>
    //                 <div className="col-6 px-0 w-100 overflow-hidden">
    //                     {data?.medical_id?.speciality_type}
    //                 </div>
    //                 <div className="col-6 px-0 w-100">
    //                     Joined:
    //                 </div>
    //                 <div className="col-6 px-0 w-100">
    //                     {data?.medical_id?.joined_date&&moment(new Date(data.medical_id.joined_date)).format('DD/MM/YYYY')}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div
      key={index}
      className="col-12 col-md-6 col-lg-4 p-2 physician-new-box"
      style={{ position: "relative" }}
    >
      {showDetails && (
        <div
          className="row"
          id="threedot"
          style={{
            position: "absolute",
            top: "1",
            right: "0",
            zIndex: 10,
            width: "100%",
          }}
        >
          <div className="col-4"></div>
          <div
            className="col-7 col-8 bg-light rounded me-1 p-4"
            ref={detailsRef}
          >
            <div className="container">
              <div className="d-flex justify-content-start">
                <span className="mx-2">Active/Deactive :</span>
                <Switch
                  checkedChildren="✔"
                  unCheckedChildren="✔"
                  className="fw-bold"
                  checked={isActive}
                  onChange={handleToggle}
                  style={{ backgroundColor: isActive ? "green" : "" }}
                />
              </div>
              <div className="actions d-flex flex-column ">
                <div className="edit-button-1 p-1">
                  <div className="btn btn-primary mb-2">
                    <TbPencil size={17} />
                  </div>{" "}
                  <span className="ps-1">Edit</span>
                </div>
                <div className="remove-button-1 p-1">
                  <div className="btn btn-danger">
                    <RiDeleteBinLine size={17} />
                  </div>{" "}
                  <span className="ps-1">Remove</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row m-1 py-2 px-1 ps-3 d-flex justify-content-between">
        <div className="col-4 px-0 pro-image-cont">
          {/* <div className="col-4 col-md-3 col-lg-2 p-0 physician-img-container"> */}
          <img
            src={MEDIA_URL + data?.basic_info?.image}
            alt=""
            className="physician-profile-image "
            onClick={() => functions(data.id)}
          />
          {/* </div> */}
        </div>
        <div className="col-6 px-0 pt-2">
          <span className="label">ID : </span>
          <span className="text">{data?.employee_id}</span>
          <br />
          <span className="label">Name : </span>
          <span className="text"> {data?.basic_info?.full_name}</span>
        </div>
        <div className="col-2 px-0 d-flex justify-content-end align-items-start">
          <input
            className="bg-light rounded fw-bold ps-2 pe-2 pb-2"
            type="button"
            value={"..."}
            onClick={handleShowDetails}
          />
        </div>
      </div>
      <div className="row m-1 p-3 physician-new-box1">
        <div className="col-5 text-start">Qualification :</div>
        <div className="col-7 text-end">
          {data?.qualifications &&
            data?.qualifications?.map((item, index) => {
              if (index !== data.qualifications.length - 1) {
                return item + ", ";
              } else {
                return item;
              }
            })}
        </div>
        <div className="col-5 text-start">Speciality :</div>
        <div className="col-7 text-end">
          {data?.medical_id?.speciality_type}
        </div>
        <div className="col-5 text-start">Joining Date :</div>
        <div className="col-7 text-end">
          {data?.medical_id?.joined_date &&
            moment(new Date(data.medical_id.joined_date)).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
};

export default PhysicianListCard;
