import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='col-12 footer-container'>
      
    </div>
  )
}

export default Footer
