import sample_profile from '../../../assets/pictures/images/sample_profile.png'
import { GoBell, GoBellFill } from 'react-icons/go'
import { IoSettings, IoSettingsOutline } from 'react-icons/io5'
import React, { useEffect, useState } from 'react'
import './Header.css'
import SearchInputBox from '../../shared/form-components/search-box/SearchInputBox'
import useAuth from '../../hooks/useAuth'
import { MEDIA_URL } from '../../axios'
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const [showPopup, setShowPopup] = useState(false)
    const [settingsFocus, setSettingsFocus] = useState(false)
    const [bellFocus, setBellFocus] = useState(false)
    const [name, setName] = useState("")
    const [role, setRole] = useState("")
    const [image, setImage] = useState("")
    const navigate = useNavigate()

    const { auth, setAuth } = useAuth()

    useEffect(() => {
        setName(auth.name)
        setRole(auth.role)
        setImage(auth.image)
    }, [])

    const handleLogout = async ()=>{
        try {
            localStorage.setItem("username", "")
            localStorage.setItem("password", "")
            localStorage.setItem("accessToken", "")
            localStorage.setItem("role", "")
            localStorage.setItem("image", "")
            localStorage.setItem("name", "")
            setAuth("")
            navigate("/login")
        } catch (err) {}
    }

    const popupContent = (
        <Popover
            className='profile-popup-container'
        >
            <div 
                className='profile-popup-content mb-2'
                onClick={handleLogout}
                >
                <div className='col-1'></div>
                <div className='col text-end'>Logout</div>
            </div>
            <div className='profile-popup-content mb-2'>
                <div className='col-1'></div>
                <div className='col text-end'>Profile</div>
            </div>
        </Popover>
    )

    return (
        <div className='row nav-container p-0 m-0'>
            <div className='col-7'></div>
            <div className='col-5'>
                <div className='nav-menu-container row'>
                    <div className="col-6">
                        <SearchInputBox />
                    </div>
                    <div 
                        className='col-1'
                    >
                        <span 
                            className='mouse-pointer'
                            onClick={()=>navigate('/settings')}
                            onMouseEnter={()=>setSettingsFocus(true)}
                            onMouseLeave={()=>setSettingsFocus(false)}
                        >
                            {settingsFocus?
                                <IoSettings size='22.8px' color='#6F7D9A' />:
                                <IoSettingsOutline size='22.8px' color='#6F7D9A' />
                            }
                        </span>
                    </div>
                    <div className='col-1'>
                        <span 
                            className='mouse-pointer'
                            onClick={()=>navigate('/settings')}
                            onMouseEnter={()=>setBellFocus(true)}
                            onMouseLeave={()=>setBellFocus(false)}
                        >
                            {bellFocus?
                                <GoBellFill size='24.3px' color='#6F7D9A' className='mouse-pointer' />:
                                <GoBell size='23.3px' color='#6F7D9A' className='mouse-pointer' />
                            }                            
                        </span>
                    </div>
                    <div className='col-4 row nav-profile-container'>
                        <div className='col-9 text'>
                            <div className='row profile-name'>{name}</div>
                            <div className='row profile-role'>({role})</div>
                        </div>
                        <div className='col-3 img'>
                            <ButtonToolbar>
                                <OverlayTrigger
                                    show={showPopup}
                                    trigger={['click']}
                                    placement={"bottom"}
                                    overlay={popupContent}
                                >
                                    <img
                                        src={image ? MEDIA_URL + image : sample_profile}
                                        className='profile-img-rnd mouse-pointer'
                                        onClick={() => setShowPopup(!showPopup)}
                                        alt=""
                                    />
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
