import React, { useState } from "react";
import "../login/Login.css";
import useHospitalServices from "../../services/api-services/hospitalServices";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import './NewHospital.css';
const NewHospital = () => {
  const initialState = {
    hospital_name: "",
    logo: null,
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    address_line_1: "",
    country: "",
  };
  const [hospitalData, setHospitalData] = useState(initialState);
  const { postHospitalRegistration } = useHospitalServices();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(hospitalData);
    const HospitalFormData = new FormData();
    Object.keys(hospitalData).map((data) =>
      HospitalFormData.append(data, hospitalData[data])
    );
    try {
      const response = await postHospitalRegistration(HospitalFormData);
      if (response.success) {
        Swal.fire("Hospital Created Successfully", "", "success");
        navigate("/login");
      } else {
        Swal.fire("Hospital Creation Failed", "", "error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    const { name, value, files, type } = e.target;
    if (type === "file") {
      setHospitalData({ ...hospitalData, [name]: files[0] });
    } else {
      setHospitalData({ ...hospitalData, [name]: value });
    }
  };
  return (
    <div className="vh-100 d-flex align-items-center bgc">
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-11 col-sm-10 col-md-8 bg-light border border-dark rounded p-sm-5 pt-5">
          <form onSubmit={handleSubmit}>
            <h3 className="text-center fw-bolder">Hospital Register</h3>
            <div className="w-100 p-2">
              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">First Name *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.first_name}
                    name="first_name"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    autoFocus
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Last Name *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.last_name}
                    name="last_name"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Username *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.username}
                    name="username"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Password *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="password"
                    value={hospitalData.password}
                    name="password"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Hospital Name *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.hospital_name}
                    name="hospital_name"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  {" "}
                  <label className="fw-bold">Hospital Logo *</label>
                </div>
                <div className="col-8 ps-2 overflow-hidden">
                  {" "}
                  <input
                    type="file"
                    name="logo"
                    onChange={(e) => handleChange(e)}
                    required
                    className="w-100 overflow-hidden "
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Location *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.address_line_1}
                    name="address_line_1"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <div className="col-4 text-start">
                  <label className="fw-bold">Country *</label>
                </div>
                <div className="col-8 ps-2">
                  <input
                    type="text"
                    value={hospitalData.country}
                    name="country"
                    onChange={(e) => handleChange(e)}
                    className="rounded ps-3 py-1 w-100 nh-input"
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center p-3">
                <button
                  type="submit"
                  className="p-2 border rounded bg-primary text-light"
                >
                  submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>

    
  );
};
export default NewHospital;
