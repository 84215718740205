import React, { useState } from 'react'
import DashboardChart from './components/DashboardChart'
import doclogo from '../../../assets/pictures/icons/svg/2.svg'
import applogo from '../../../assets/pictures/icons/svg/1.svg'
import patlogo from '../../../assets/pictures/icons/svg/3.svg'
import AppointmentTable from './components/AppointmentTable'
import PhysicianTable from './components/PhysicianTable'
import './Dashboard.css'
const Dashboard = () => {
    const initialDatas = [
        {name:"Total Appointments", value:"100",image : applogo,type:"appointment"},
        {name:"Total Physicans", value:"100",image : doclogo,type:"physician"},
        {name:"Total Patients", value:"100",image : patlogo,type:"patient"}]
    const [initialData,setInitial] = useState(initialDatas)
  return (
    <div className='me-5 ms-3 vh-100'>
        <div className='mainhead row'>
            <div className='subhead col-4 px-5 py-3'>
                {initialData.map((d,i)=>{
                    return(
                        <div className='row border rounded mb-2 p-1' key={i}>
                        <div className='col-3 p-3 ps-5 d-flex justify-content-center rounded'>
                            <img src={d.image} alt="" />
                        </div>
                        <div className='col-9 ps-5'>
                            <div className='mt-3 pt-2'><h2>{d.value}+</h2></div>
                            <div className='py-1'><h4>{d.name}</h4></div>
                        </div>
                    </div>
                    )
                })}
            </div>
            <div className='subgraph col-8 p-5'>
              <DashboardChart/>
            </div>
        </div>
        <div className='mainbase row'>
        <div className='baselist1 col-8 row p-4'>
            <div className='w-100 ms-3'> 
                <h3>Appointments</h3>
                <AppointmentTable/>
            </div>
        </div>
        <div className='baselist2 col-4 row p-4'>
            <div className='w-100 ms-3'>
                <h3>Available Physicians</h3>
                <PhysicianTable/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Dashboard