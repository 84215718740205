import React, { useState } from 'react'
import { Form, Popover } from 'react-bootstrap'
import MultiInputBox from '../../../../../../shared/form-components/multi-input/MultiInputBox'
import InputTextBox from '../../../../../../shared/form-components/form-text/InputTextBox'
import calender_icon from '../../../../../../../assets/pictures/icons/calender-icon.png'
import DocumentUploadCard from '../DocumentUploadCard'
import DocumentUploadList from '../DocumentUploadList'
import TreatmentHistoryList from '../TreatmentHistoryList'
import Calendar from 'react-calendar'
import InputBtnGroup from '../../../../../../shared/form-components/form-text/InputBtnGroup'
import moment from 'moment'
import DropDownBox from '../../../../../../shared/form-components/dropdown/CustomDropDown'
import { useNavigate } from 'react-router-dom'


const MedicalRecords = ({ setKey, patientType, value, setValue, functions, consult }) => {
  const [showDropdown, setShowDropdown] = useState('')
  const [showIssueCalendar, setShowIssueCalendar] = useState(false)
  const [showValidityCalendar, setShowValidityCalendar] = useState(false)
  const navigate = useNavigate()

  const handleIssueCalendar = (e) => {
    setValue?.setDocumentIssueDate(e)
    setShowIssueCalendar(false)
  }

  const handleValidityCalendar = (e) => {
    setValue?.setDocumentValidity(e)
    setShowValidityCalendar(false)
  }

  const docIssueCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar
        onChange={handleIssueCalendar}
        value={value?.documentIssueDate}
      />
    </Popover>
  )

  const docValidityCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar
        onChange={handleValidityCalendar}
        value={value?.documentValidity}
      />
    </Popover>
  )
  return (
    <div className='row'>
      <div className="col-6 page-title-label">Medical Records</div>
      <div className="col-6 ps-2 page-title-label">Document Upload</div>
      <div className='left-split-container col-6 row'>
        <MultiInputBox
          label={'Previous Medical Issues'}
          data={value?.prevMedicalRecords && value?.prevMedicalRecords}
          onAdd={functions?.addPrevMedIssues}
          onRemove={functions?.deleteMedIssue}
          containerClass={'col-12 mb-3'}
          icon={'+'}
        />
        <MultiInputBox
          label={'Current Medical Issues'}
          data={value?.currentMedicalRecords && value?.currentMedicalRecords}
          onAdd={functions?.addCurrentMedIssues}
          onRemove={functions?.deleteMedIssue}
          containerClass={'col-12 mb-3'}
          icon={'+'}
        />
        <Form.Group className={'col-12 mb-3'}>
          <Form.Label
            className='label-text-content'
          >
            Treatment History
          </Form.Label>
          <TreatmentHistoryList
            data={value?.treatmentHistory && value?.treatmentHistory}
            onAdd={functions?.addTreatment}
            onRemove={functions?.removeTreatment}
          />
        </Form.Group>
        <MultiInputBox
          label={'Known Allergies'}
          data={value?.allergyList && value?.allergyList}
          onAdd={functions?.addAllergy}
          onRemove={functions?.deleteAllergy}
          containerClass={'col-12 mb-3'}
          icon={'+'}
        />
      </div>
      <div className='col-6 row ms-2 ps-3 pe-0 me-0'>
        
        {value?.documentCategoryList && <DropDownBox
          required={true}
          label={'Document Category'}
          containerClass={'col-6 mb-3'}
          fluid={true}
          search={true}
          addNew={true}
          setNew={functions?.postPatientDocumentType}
          options={value?.documentCategoryList}
          setDataValue={setValue?.setDocumentCategory}
          selectedValue={value?.documentCategory}
        />}
        <span className="col-6 mb-3" />
        <InputBtnGroup
          label={'Issued Date'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.documentIssueDate && moment(new Date(value?.documentIssueDate)).format("DD - MM - YYYY")}
          setTogglePopup={setShowIssueCalendar}
          togglePopup={showIssueCalendar}
          popupContent={docIssueCalendar}
          popupPlacement={"bottom"}
        />
        <InputBtnGroup
          label={'Valid Upto'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.documentValidity && moment(new Date(value?.documentValidity)).format("DD - MM - YYYY")}
          setTogglePopup={setShowValidityCalendar}
          togglePopup={showValidityCalendar}
          popupContent={docValidityCalendar}
          popupPlacement={"bottom"}
        />
        <div className="col-12">
          <DocumentUploadCard
            documents={value?.documentList}
            fileId={value?.documentFileId}
            setDocuments={setValue?.setDocumentList}
            handleSubmit={functions?.handleDocumentUpload}
            patientUpdate={functions?.handleUpdateDocument}
            containerClass={''}
          />
        </div>
        <div className="col-12">
          <DocumentUploadList
            data={value?.patientDocuments && value?.patientDocuments}
            datalist={value?.documentCategoryList && value?.documentCategoryList}
            patientDelete={functions?.handleDeleteDocument}
            setDList={setValue?.setDocumentList}
            setDvalidity={setValue?.setDocumentValidity}
            setDIssueDate={setValue?.setDocumentIssueDate}
            setDCategory={setValue?.setDocumentCategory}
            setDFileId={setValue?.setDocumentFileId}
          />
        </div>
      </div>
      {!consult && <div className='col-12 row me-0 pe-0'>
        <InputTextBox
          label={'Height'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.patientHeight}
          onChange={setValue?.setPatientHeight}
        />
        <InputTextBox
          label={'Weight'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.patientWeight}
          onChange={setValue?.setPatientWeight}
        />
        <InputTextBox
          label={'Blood Pressure'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.bloodPressure}
          onChange={setValue?.setBloodPressure}
        />
        <InputTextBox
          label={'Pulse'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.pulse}
          onChange={setValue?.setPulse}
        />
        <InputTextBox
          label={'Respiration'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.respiration}
          onChange={setValue?.setRespiration}
        />
        <InputTextBox
          label={'Temperature'}
          required={false}
          containerClass={'col-2 mb-3'}
          defvalue={value?.temperature}
          onChange={setValue?.setTemperature}
        />
      </div>}
      <div className="col-12 row me-0 pe-0">
        <span className='col-6 mt-4' />
        {consult ?
          <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
            <div
              className='form-next-btn btn col-3 me-0'
              onClick={()=>setKey('tab3')}
            >
              Next
            </div>
          </div>
          :
          <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
            <div
              className='form-previous-btn btn col-3 me-3'
              onClick={(e) => setKey(patientType === 'native' ? 'tab1' : 'tab2')}
            >
              Previous
            </div>
            <div
              className='form-cancel-btn btn col-3 me-3'
              onClick={()=>navigate('/patient-list')}
            >
              Cancel
            </div>
            <div
              className='form-next-btn btn col-3 me-0'
              onClick={functions?.handleFinalSubmit}
            >
              Save & Submit
            </div>
          </div>}
      </div>
    </div>
  )
}

export default MedicalRecords
