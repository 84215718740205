import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { MEDIA_URL } from "../../../../../axios";

const PhysicianForm = ({ physicianData }) => {
  return (
    <div
      className="container-fluid"
      style={{ height: "400px", overflow: "scroll" }}
    >
      <div className="row">
        {/* Left Side Form */}
        <div className="col-md-6">
          <div
            className="card mb-3 mt-0 p-3"
            style={{
              backgroundColor: "#fffaf4",
              border: "none",
              borderRadius: "15px",
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <img
                src={
                  MEDIA_URL + physicianData.image ||
                  "https://via.placeholder.com/100"
                }
                alt="Profile"
                className="rounded-circle me-3"
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "cover",
                }}
              />
              <div className="d-flex flex-column align-items-start">
                <h6 className="text-primary" style={{ fontSize: "14px" }}>
                  <span
                    className="me-2"
                    style={{ color: "#333", fontSize: "14px" }}
                  >
                    ID :
                  </span>{" "}
                  {physicianData.id || "N/A"}
                </h6>
                <h6 className="fw-bold mb-2" style={{ fontSize: "18px" }}>
                  {physicianData.basicInfo?.full_name || "N/A"}
                </h6>
              </div>
            </div>

            <div
              className="bg-white p-3 rounded"
              style={{
                borderRadius: "10px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="row">
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Qualification :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    {physicianData.basicInfo?.qualification || "N/A"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Speciality :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    {physicianData.specialtyType || "N/A"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    Department :
                  </p>
                </div>
                <div className="col-6 text-end">
                  <p className="mb-1" style={{ color: "#5d5d5d" }}>
                    {physicianData.department || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h5>Physician Details</h5>
            <div className="row mb-3">
              <div className="col">
                <label>ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.id || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Joined Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    moment(physicianData.joinedDate).format("MMMM D, YYYY") ||
                    ""
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.title || "N/A"}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.basicInfo?.full_name || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Licence No.</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.licenseNo || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Speciality</label>
                <select className="form-select" disabled>
                  <option>{physicianData.specialtyType || "Select"}</option>
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Licence Expiry Date</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    moment(physicianData.licenseExpiryDate).format(
                      "MMMM D, YYYY"
                    ) || ""
                  }
                  readOnly
                />
              </div>
              <div className="col">
                <label>Qualification</label>
                <div className="d-flex">
                  <button className="btn btn-outline-primary me-2" disabled>
                    MBBS
                  </button>
                  <button className="btn btn-outline-primary" disabled>
                    MD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side Form */}
        <div className="col-md-6">
          <div>
            <h5>Personal Information</h5>
            <div className="row mb-3">
              <div className="col">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={physicianData.email || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.mobile || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Date Of Birth</label>
                <input
                  type="text"
                  className="form-control"
                  value={moment(physicianData.dob).format("MMMM D, YYYY") || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Gender</label>
                <div className="d-flex">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={physicianData.gender === "male"}
                      readOnly
                    />
                    <label className="form-check-label">Male</label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={physicianData.gender === "female"}
                      readOnly
                    />
                    <label className="form-check-label">Female</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={physicianData.gender === "other"}
                      readOnly
                    />
                    <label className="form-check-label">Other</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Country</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.country || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>State</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.state || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>District</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.district || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.city || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>PIN Code</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.pincode || ""}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.addressLine1 || ""}
                  readOnly
                />
              </div>
              <div className="col">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={physicianData.addressLine2 || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Document */}
      <div className="row mt-4">
        <div className="col-md-12">
          <h5>Upload Document</h5>
          <div className="d-flex mb-3">
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={physicianData.documents?.includes("voterId")}
                readOnly
              />
              <label className="form-check-label">Voter ID</label>
            </div>
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={physicianData.documents?.includes("certificate")}
                readOnly
              />
              <label className="form-check-label">Certificate</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={physicianData.documents?.includes("aadhaar")}
                readOnly
              />
              <label className="form-check-label">Aadhaar</label>
            </div>
          </div>
          <div className="d-flex">
            {physicianData.uploadedDocuments?.map((doc, index) => (
              <div className="card me-2" key={index}>
                <div className="card-body text-center">
                  <img
                    src={doc.thumbnail}
                    alt="Document Thumbnail"
                    className="img-thumbnail"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <p>{doc.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Consultation Timings */}
      <div className="row mt-4">
        <div className="col-md-12">
          <h5>Consultation</h5>
          <table className="table">
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Day</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {physicianData.consultationTimings?.map((timing, index) => (
                <tr key={index}>
                  <td>{timing.from}</td>
                  <td>{timing.to}</td>
                  <td>{timing.day}</td>
                  <td>
                    <button className="btn btn-danger btn-sm" disabled>
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PhysicianForm;
