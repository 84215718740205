import React, { useState } from 'react'
import './SearchBox.css'
import { GrSearch } from 'react-icons/gr'

const SearchInputBox = () => {
    const [focus, setFocus] = useState(false)
    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        console.log(value)
        e.target.value = ''
    }
    return (
        <div className={`search-input-container ${focus && 'highlight'}`}>
            <div className="search-input-icon">
                <GrSearch color='#818181' size='15px'/>
            </div>
            <input
                onKeyDown={handleKeyDown}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                type="text"
                className="search-input-box"
                placeholder={'Search Here..'}
            />
        </div>
    )
}

export default SearchInputBox
