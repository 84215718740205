import React from 'react'
import Barcode from 'react-barcode'

const IdBackPreview = ({patient, useref, title, point1, point2, point3, point4}) => {
    return (
        <div className='patient-card-container p-1' ref={useref}>
            <div className='card-back-details'>
                <div className="card-back-title row mx-0 py-2">{title?title.toUpperCase():'TITLE'}</div>
                <ul className="card-point-container row mx-0">
                    {point1&&<li className='card-back-point'>{point1}</li>}
                    {point2&&<li className='card-back-point'>{point2}</li>}
                    {point3&&<li className='card-back-point'>{point3}</li>}
                    {point4&&<li className='card-back-point'>{point4}</li>}
                </ul>
            </div>
            <div className='card-barcode-container px-4'>
                <Barcode
                    value={patient?.patientId&&patient.patientId}
                    width={2}
                    height={35}
                    fontSize={10}
                    font='roboto mono'
                    lineColor='#000000'
                    textMargin={5}
                    background='transparent'
                    marginTop={1}
                    marginBottom={1}
                />
            </div>
        </div>
    )
}

export default IdBackPreview
