import { createContext, useState } from "react";


const AuthContext = createContext({})

export const AuthProvider = ({children})=>{
    const [auth,setAuth]=useState({});

    const accessToken = localStorage.getItem("accessToken");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const image = localStorage.getItem("profileImage");
    const name = localStorage.getItem("name")
    const role = localStorage.getItem("role");

    if (accessToken && role && !auth.accessToken) {
        setAuth({ username, password, accessToken, role, image, name });
    };
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )


}

export default AuthContext;