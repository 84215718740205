import React from 'react'

const SubSymptoms = ({data}) => {
  return (
    <div>
        <label className='mb-2 fw-bold h6'>Symptoms</label>
        {data&&data?.map((item, index)=>{
          return(
            <div className='row p-2 symp-row'>
              <div className='col-1 symp bg-dark text-white' >{index+1}</div>
              <div className='col-11 symp bg-white'>{item?.description}</div>
            </div>
          )
        })}        
    </div>
  )
}

export default SubSymptoms