import axios from "axios";

// Local Server
// export const BASE_URL = "http://localhost:8000/api/v2/"
// export const MEDIA_URL = "http://localhost:8000"

// Pranavam Server
// export const BASE_URL = "https://pranavam-api.foxa.in/api/v2/"
// export const MEDIA_URL = "https://pranavam-api.foxa.in"

export const BASE_URL = "https://pranavam-api.foxa.in/api/v1/";
export const MEDIA_URL = "https://pranavam-api.foxa.in";

// Pranavam Server
// export const BASE_URL = "http://192.168.1.8/api/v1/"
// export const MEDIA_URL = "http://192.168.1.8/"


export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
