import React, { useState } from 'react'
import InputTextBox from '../../../../../../shared/form-components/form-text/InputTextBox'
import InputUploadBox from '../../../../../../shared/form-components/form-upload/InputUploadBox'
import TextAreaBox from '../../../../../../shared/form-components/form-textarea/TextAreaBox'
import calender_icon from '../../../../../../../assets/pictures/icons/calender-icon.png'
import { Popover } from 'react-bootstrap'
import Calendar from 'react-calendar'
import InputBtnGroup from '../../../../../../shared/form-components/form-text/InputBtnGroup'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const PassportAndVisa = ({ setKey, value, setValue, functions }) => {
  const [showPassportCalendar, setShowPassportCalendar] = useState(false)
  const [showVisaCalendar, setShowVisaCalendar] = useState(false)
  const navigate = useNavigate()

  const handlePassportCalendar = (e) => {
    setValue?.setPassportExpiry(e)
    setShowPassportCalendar(false)
  }
  const handleVisaCalendar = (e) => {
    setValue?.setVisaValidity(e)
    setShowVisaCalendar(false)
  }
  const PassportExpiryCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar onChange={handlePassportCalendar} value={value?.passportExpiry} />
    </Popover>
  )
  const VisaValidityCalendar = (
    <Popover
      className='calendar-popup-container'
    >
      <Calendar onChange={handleVisaCalendar} value={value?.visaValidity} />
    </Popover>
  )
  return (
    <div className='row'>
      <div className="col-6 page-title-label">Passport</div>
      <div className="col-6 ps-2 page-title-label">Visa</div>
      <div className='left-split-container col-6 row'>
        <InputTextBox
          label={'Passport Number'}
          required={false}
          containerClass={'col-6 mb-3'}
          defvalue={value?.passportNumber}
          onChange={setValue?.setPassportNumber}
        />
        <InputBtnGroup
          label={'Date Of Expiry'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.passportExpiry && moment(new Date(value?.passportExpiry)).format("DD - MM  - YYYY")}
          setTogglePopup={setShowPassportCalendar}
          togglePopup={showPassportCalendar}
          popupContent={PassportExpiryCalendar}
          popupPlacement={"bottom"}
        />
        <div className='col-12 px-3'>
          <InputUploadBox
            containerClass=''
            handleFile={setValue?.setPassport}
            fileValue={value?.passport}
            id={'passport'}
          />
        </div>
      </div>
      <div className='col-6 row ms-2 ps-3 pe-0 me-0'>
        <InputTextBox
          label={'Visa Type'}
          required={false}
          containerClass={'col-6 mb-3'}
          defvalue={value?.visaType}
          onChange={setValue?.setVisaType}
        />
        <InputBtnGroup
          label={'Validity Period'}
          icon={
            <img
              src={calender_icon}
              alt=""
              className='search-icon-img'
            />
          }
          containerClass={'col-6 mb-3'}
          defvalue={value?.visaValidity && moment(new Date(value?.visaValidity)).format("DD - MM  - YYYY")}
          setTogglePopup={setShowVisaCalendar}
          togglePopup={showVisaCalendar}
          popupContent={VisaValidityCalendar}
          popupPlacement={"bottom"}
        />
        <TextAreaBox
          label={'Purpose of Visit'}
          required={false}
          rows={'3'}
          containerClass={'col-6 mb-3'}
          defvalue={value?.visaPurpose}
          onChange={setValue?.setVisaPurpose}
        />
        <TextAreaBox
          label={'Remarks'}
          required={false}
          rows={'3'}
          containerClass={'col-6 mb-3'}
          defvalue={value?.visaRemarks}
          onChange={setValue?.setVisaRemarks}
        />
        <div className='col-12 px-3 my-3'>
          <InputUploadBox
            containerClass=''
            handleFile={setValue?.setVisa}
            fileValue={value?.visa}
            id={'visa'}
          />
        </div>
      </div>
      <div className="col-12 row me-0 pe-0">
        <span className='col-6 mt-4' />
        <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
          <div
            className='form-previous-btn btn col-3 me-3'
            onClick={(e) => setKey('tab1')}
          >
            Previous
          </div>
          <div
            className='form-cancel-btn btn col-3 me-3'
            onClick={()=>navigate('/patient-list')}
          >
            Cancel
          </div>
          <div
            className='form-next-btn btn col-3 me-0'
            onClick={functions?.handleTabChange}
          >
            Save & Next
          </div>
        </div>
      </div>
    </div>
  )
}

export default PassportAndVisa
